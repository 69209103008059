<template>
  <div class="FAQDetail">
    <div class="block" >
        <div class="blockTitle" >{{ question }}</div>
        <div class="blockBody" v-html="answer"></div>
        <div class="buttonList">
          <div :class="okButtonStatus" @click="ok">
            <span class="smileLogo"></span><span>已解决</span>
          </div>
          <div :class="noButtonStatus" @click="no">
            <span class="cryLogo"></span><span>未解决</span>
          </div>
        </div>
        <div class="textareaBox" v-if="afternobuttonShow">
          <span class="wordLimit">{{wordLimit}}/200</span>
          <textarea name="" id="" maxlength="200" rows="10" v-model="textareaWord" @input="textareaChange"></textarea>
        </div>
        <div class="submitButtonBox" v-if="afternobuttonShow">
          <div class="submitButton" @click="submitMsg">
            提交
          </div>
        </div>
        <div class="okalert" v-if="okalertShow">提交成功</div>
    </div>
  </div>
</template>

<script>
export default {
        data(){
            return{
                question:"1",
                answer:"2",
                okalertShow:false,
                timer:null,
                okButtonStatus:"okButton",
                noButtonStatus:"okButton noButton",
                textareaWord:"",
                wordLimit:0,
                afternobuttonShow:false
            }
        },
        mounted(){
          console.log(this.$route.query)
          this.question = this.$route.query.title;
          this.answer = this.$route.query.answer
        },
        methods:{
          ok(){
            this.okalertShow = true;
            this.okButtonStatus = "okButton okButtonActive";
            this.noButtonStatus = "okButton noButton";
            this.afternobuttonShow = false
            this.timer = setTimeout(()=>{
              this.okalertShow = false;
            },3000)
          },
          no(){
            this.noButtonStatus = "okButton noButton noButtonActive";
            this.okButtonStatus = "okButton";
            this.afternobuttonShow = true
          },
          beforeDestroy() {
            clearTimeout(this.timer)
            this.timer = null
          },
          textareaChange(){
            this.wordLimit = this.textareaWord.length
          },
          submitMsg(){
            this.okalertShow = true;
            this.timer = setTimeout(()=>{
              this.okalertShow = false;
            },3000)
          },
        }
    
}
</script>

<style scoped>
    .FAQDetail{
        text-align:left;
        background-color: #F7F7F7;
        min-height: calc(100% - 24px);
        padding-top: 12px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #fff;
        box-sizing: border-box;
        margin: 12px;
    }
    .submitButtonBox{
      margin:12px
    }
    .submitButton{
      width: 100%;
      height: 44px;
      line-height: 44px;
      text-align: center;
      background: #FC9125;
      border-radius: 22px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;
      font-style: normal;
      box-sizing: border-box;
    }
    .buttonList{
      margin: 12px;
      margin-top: 38px;
      position: relative;
    }
    .textareaBox{
      margin: 12px;
      margin-top: 38px;
      position: relative;
    }
    .wordLimit{
      position: absolute;
      right: 8px;
      bottom: 8px;
      height: 17px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #909090;
      line-height: 17px;
      text-align: left;
      font-style: normal;
    }
    .textareaBox textarea{
      width: 100%;
      height: 100px;
      border-radius: 8px;
      padding: 12px;
      box-sizing: border-box;
      background-color: #F1F1F1 ;  
      border: none;
   }
    .okButton,.noButton{
      width: 40vw;
      height: 40px;
      border-radius: 20px;
      border: 1px solid #E1E1E1;
      text-align: center;
      line-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .noButton{
      float: right;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    .okButtonActive{
      background-color: #FFE9D4;
      border: none;
    }
    .noButtonActive{
      background-color: #FFE9D4;
      border: none;
    }
    .block{
    }
    .smileLogo{
      display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(../../assets/icon_solve.png);
        background-size: contain;
    }
    .cryLogo{
      display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(../../assets/icon_unsolve.png);
        background-size: contain;
    }
    .block .blockTitle{
        font-weight: bold;
        padding: 12px;
        margin: 12px;
        font-size: 15px;
        border-bottom: 0.5px solid rgba(216, 216, 216, 0.5) ;
        position: relative;
    }
    .block .blockTitleHideline{
        font-weight: bold;
        padding: 12px;
        margin: 12px;
        font-size: 15px;
        position: relative;
    }
    .block .blockBody{
        margin: 24px;
        padding-bottom: 12px;
        font-size: 13px;
        position: relative;
        text-align: justify;
    }
    .okalert{
      width: 160px;
      height: 69px;
      background: rgba(0,0,0,0.5);
      border-radius: 10px;
      text-align: center;
      line-height: 69px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
      font-style: normal;
      position: absolute;
      top: calc(50% - 34px);
      left: calc(50% - 80px);
    }
    @media (-webkit-min-device-pixel-ratio:2) {
      .smileLogo{
      display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(../../assets/icon_solve@2x.png);
        background-size: contain;
      }
      .cryLogo{
        display: inline-block;
          width: 20px;
          height: 20px;
          background-image: url(../../assets/icon_unsolve@2x.png);
          background-size: contain;
      }
    }
    /* 屏幕宽度小于375时设置样式 */
    @media screen and (max-width: 374px) {
      .okButton,.noButton{
        width: 40vw;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #E1E1E1;
        text-align: center;
        line-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
</style>
