'use strict';
const watermark = {};
let timeId = null
/**
 * 水印
 * @param {*} params 水印配置
 * @returns
 */
 const setWatermark = (params) => {
  const {
    rotate = '-10',
    opacity = '0.6',
    x_space = 150,
    y_space = 150,
    fontSize = 16,
    fontFamily = 'Microsoft YaHei',
    text,
    id = 'watermark_0001'
  } = {
    rotate: params.rotate, // 水印角度
    opacity: 1 - params.opacity * 1, // 水印透明度
    x_space: params.x_space, // 水印间隔宽度
    y_space: params.y_space, // 水印间隔高度
    fontSize: params.fontSize, // 水印字体
    fontFamily: params.fontFamily,
    text: params.text, // 水印内容
    id: params.id // 水印内容
  };
  let el = document.getElementById(id)
  if (!el) {
    el = document.createElement('div')
    el.id = id
    document.body.appendChild(el);
  }
  const { width, height } = textSize(text, fontSize, fontFamily); // 获取文字的高度
  const elWidth = el.offsetWidth; // 获取父容器宽
  const elHeight = el.offsetHeight; // 获取父容器宽
  let allWater = document.getElementsByClassName(id + 'cus_water');
  let len = allWater.length;
    // 清空所有水印元素
  for (let i = 0; i < len; i++) {
    allWater[0] && allWater[0].parentNode.removeChild(allWater[0]);
  }
  // 创建虚拟元素
  const oTemp = document.createDocumentFragment();
  // 水印数量自适应元素区域尺寸
  const cols = Math.ceil(elWidth / (x_space + width));
  const rows = Math.ceil(elHeight / (y_space + height));
  for (let i = 0; i < rows; i++) {
    const y = (height + y_space) * i;
    for (let j = 0; j < cols; j++) {
      const x = (width + x_space) * j;
      let div = document.createElement('div');
      div.className = id + 'cus_water';
      div.innerHTML = text;
      // 设置水印div倾斜显示
      div.style.MozTransform = 'rotate(' + rotate + 'deg)';
      div.style.msTransform = 'rotate(' + rotate + 'deg)';
      div.style.OTransform = 'rotate(' + rotate + 'deg)';
      div.style.transform = 'rotate(' + rotate + 'deg)';
      // 设置水印原点
      const origin = rotate < 0 ? 'right top' : 'left bottom'
      div.style.MozTransformOrigin = origin;
      div.style.msTransformOrigin = origin;
      div.style.OTransformOrigin = origin;
      div.style.transformOrigin = origin;
      div.style.position = 'absolute';
      div.style.left = x + 'px';
      div.style.top = y + 'px';
      div.style.overflow = 'hidden';
      div.style.zIndex = '1000';
      // pointer-events:none  让水印不遮挡页面的点击事件
      div.style.pointerEvents = 'none';
      // 兼容IE9以下的透明度设置
      div.style.filter = `alpha(opacity=${opacity})`;
      div.style.opacity = opacity;
      div.style.fontSize = fontSize;
      div.style.fontFamily = fontFamily;
      div.style.color = 'rgb(200, 200, 200)';
      div.style.textAlign = 'center';
      div.style.width = width + 'px';
      div.style.height = height + 'px';
      div.style.display = 'block';
      oTemp.appendChild(div);
    }
  }
  el.style.overflow = 'hidden';
  el.style.pointerEvents = 'none';
  el.style.zIndex = '9999';
  el.appendChild(oTemp);
  return id
};
// 获取文本的高度和宽度
function textSize(text, fontSize, fontFamily) {
  const id = 'textStyle_1_00';
  let span = document.createElement('span');
  let width = span.offsetWidth;
  let height = span.offsetHeight;
  span.style.visibility = 'hidden';
  span.style.fontSize = fontSize;
  span.style.fontFamily = fontFamily;
  span.style.display = 'inline-block';
  span.id = id;
  document.body.appendChild(span);
  span.innerHTML = text;
  width = parseFloat(window.getComputedStyle(span).width) - width;
  height = parseFloat(window.getComputedStyle(span).height) - height;
  span.parentNode.removeChild(span);
  span = null
  return {
    width,
    height
  };
}

// 该方法只允许调用一次
watermark.set = (options) => {
  let id = setWatermark(options);
  clearInterval(timeId)
  timeId = null
  timeId = setTimeout(() => {
    if (document.getElementById(id) === null) {
      id = setWatermark(options);
    }
  }, 500);
  // 监听页面大小的变化
  window.onresize = () => {
    setWatermark(options);
  };
};

export default watermark;

