<template>
  <div class="home">
    <h3 class="aligncenter">“小羊淘券”隐私政策</h3>
    <p style="text-indent: 0em;">更新时间：2024年【9】月【18】日</p>
    <p style="text-indent: 0em;">生效时间：2024年【9】月【18】日</p>
    <p>大庆一只羊科技有限公司及其关联公司（简称“我们”）作为小羊淘券APP的运营者，我们深知个人信息对你的重要性，我们将按照法律法规的规定，保护你的个人信息及隐私安全。我们制定本“隐私政策”帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，希望您在使用小羊淘券及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。同时本隐私权政策适用于小羊淘券体系之下的所有产品或服务，需要特别说明的是，本隐私权政策不适用于其他第三方向您提供的产品或服务，也不适用于小羊淘券中已另行独立设置法律声明及隐私权政策的产品或服务。</p>
    <h3>本隐私政策将帮助您了解：</h3>
    <p>我们会遵循隐私政策收集、使用您的信息，并且不会仅因您同意本隐私政策而采用强制捆绑的方式一揽子收集个人信息。</p>
    <p>当您使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。除非是为实现基本业务功能、或根据法律法规要求所必需的必要信息、或我们可以依法收集而无需征得您授权同意的信息，您均可以拒绝提供且不影响其他功能或服务。我们也将在隐私政策中逐一说明。</p>
    <p><span>精确的地理位置、相册（存储）</span>均不会默认开启，只有经过您的明示授权才会在实现特定功能或服务时使用，您也可以撤回授权。<span>特别指出的是，即使经过您的授权，我们获取到了敏感权限，也不会在相关功能或者服务不需要时而收集您的信息。</span></p>
    <p>下文将帮您了解我们如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便更好的做出适当的选择，其中，<span>有关您个人信息权益的条款重要内容我们已用加粗形式提示，请您特别关注。</span>本文涉及到的专业词汇，我们尽量以简明通俗的表述向您解释，以便您理解。<span>如对本协议内容有任何疑问、意见或建议，您可以通过本协议提供的联系方式与我们联系。</span></p>
    <p><span>1、我们如何收集和使用您的信息</span></p>
    <p><span>2、我们如何共享、转让、公开纰漏您的信息</span></p>
    <p><span>3、您的个人信息权利</span></p>
    <p><span>4、我们如何存储个人信息</span></p>
    <p><span>5、您如何管理个人信息</span></p>
    <p><span>6、未成年人条款</span></p>
    <p><span>7、隐私政策的修正和通知</span></p>
    <p><span>8、联系我们</span></p>
    <h3 class="titleOne">一、我们如何收集和使用您的信息</h3>
    <p>个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：</p>
    <div class="secondTitle">1.1 注册、登录</div>
    <p>（1）当您注册、登录小羊淘券及相关服务时，您可以通过手机号创建帐号，收集这些信息是为了帮助您完成注册。当您注册时向我们提供注册所用的手机号码（用于接收验证码），我们将通过发送短信验证码来验证您的身份是否有效。您的手机号码尾号四位为您的默认昵称，登录成功之后您也可以绑定您的支付宝账号（用于账户提现）等必须相关信息</p>
    <p>（2）基于我们与通信运营商的合作，当您使用小羊淘券“一键登录”功能时，经过您的明示同意，我们会与运营商处理您的手机号码，便于我们为您提供快捷的登录服务。<span>如果拒绝上述处理，</span>将无法使用“一键登录”方式注册登录小羊淘券，但不影响您通过其他方式注册登录，也不影响其他功能的正常使用。</p>
    <div class="secondTitle">1.2 商品/服务信息展示和搜索</div>
    <p>为了让您快速地找到您所需要的商品或服务。我们可能会收集您使用我们的产品和服务的设备信息（包括设备名称、设备型号、设备识别码、操作系统和应用程序版本、语言设置、分辨率、服务提供商网络ID （PLMN））、剪切板内容和浏览类型来为您提供商品优惠券信息展示的最优方式。</p>
    <p>（1）为您提供收藏、痕迹功能</p>
    <p>在你使用小羊淘券浏览感兴趣的商品和服务时，您可以将感兴趣的商品和服务进行收藏，同时为了便于你找到浏览过的商品，我们也帮您记录浏览商品等行为。上述信息是对你使用小羊淘券各项功能的实际情况的记录。</p>
    <p>（2）搜索</p>
    <p>您使用小羊淘券的搜索服务时，我们会收集您的搜索关键字信息、日志记录。为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地存储设备之中，并可向您展示搜索结果内容、搜索历史记录。</p>
    <p>（3）交易订单及服务</p>
    <p>为了便于您了解查询部分订单信息并对部分订单信息进行管理，我们会收集您在使用我们服务过程中产品的部分订单信息（包括：订单来源（平台）、订单创建时间、订单金额、所购买的商品及/或服务信息、具体订单号、返利情况、订单状态）用于您对订单进行查询、共享、向您提供客服与售后服务及其他我们明确告知的目的。</p>
    <div class="secondTitle">1.3 分享及其他</div>
    <p>（1）在你分享或接收被分享的信息、商品搜索等情形下，我们需要访问你的剪切板，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。我们仅会对剪切板中的信息在设备本地进行判断，不会将其中的信息回传至服务器。有些手机设备的系统会提示应用(App)正在读取剪切板，但该系统提示无法区分是客户端本地处理还是上传服务器，且由于统计方式的差异，不排除误报的可能。因此，如果您发现类似提示并有疑问，请与我们联系，以便我们定位问题。</p>
    <p>（2）若相关广告页有拨打电话的功能，当您使用该功能时，我们会申请电话权限，申请该权限的目的仅为帮助您调起手机拨号键盘和显示对方电话以实现您在App内即可进行电话拨打，请您放心，我们不会通过该权限获取您的电话号码、通话内容，也不会在您不知情的情况下拨打电话。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用小羊淘券的其他功能。</p>
    <p>（3）关于平台活动以及广告，在参与我们平台提供的活动或者展示广告和使用第三方广告SDK页面的过程中，我们可能会获取<span>陀螺仪传感器</span>和<span>加速度传感器</span>数据判断设备是否摇动，以便于提供更准确的进入广告页面的判断和触发活动任务完成的需求。</p>
    <p>（4）关于位置信息，在经您授权，我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹，也不会保存您的轨迹）来判断您所处的地点。地理位置信息属于敏感信息，拒绝提供该信息只会影响您在第三方平台享受的部分服务。但不影响您正常使用小羊淘券产品的其他功能。您也可以随时取消您的地理位置信息授权。</p>
    <p>（5）在您使用上传图片功能时候，我们会根据你使用的功能类型分别去请求您授权<span>摄像头（相机）、相册（存储空间），</span>如果您拒绝授权，将无法使用对应功能，但不影响您正常使用小羊淘券其的他功能。</p>
    <p>（6）同时您可以在手机设置中逐项查看您上述权限的开启状态，并可以决定将这些权限随时的开启或关闭。<span>请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</span></p>
    <!-- <div class="secondTitle">1.4 基于开启内部审计、数据分析和研究和个性化推荐</div> -->
    <!-- <p>我们可能会收集您的订单信息、浏览信息、您的兴趣爱好进行数据分析，用来将您感兴趣的商品或服务信息展示给您;为您提供个性化服务或内容;或在您搜索时向您展示您可能希望找到的商品。例如在小羊淘券APP客户端可能设有专门的模块“猜你喜欢”的相关功能。</p> -->
    <div class="secondTitle">1.4 基于安全管理所必须的功能</div>
    <p>为保障您使用我们的产品或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，<span>我们可能会通过了解您的浏览信息、订单信息、您常用的软件信息、设备信息、设备MAC地址、操作系统类型唯一设备识别码手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接(“URL”));我们也会收集您的设备信息(包括设备型号、操作系统版本、设备设置、MAC地址及IMEI、 IDFA、OAID这些设备标识符、设备环境软硬件特征信息)对于小羊淘券APP使用问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。</span></p>
    <div class="secondTitle">1.5 纳税保证</div>
    <p>为便于向税务部门纳税，在您提现时，我们会在征得您同意后收集您的身份信息，您的身份信息将用于纳税认证。如果您拒绝提供身份信息，将有可能影响您相关的提现服务。</p>
    <div class="secondTitle">1.6 运营与安全保障</div>
    <p>（1）运营与安全</p>
    <p>我们致力于为您提供安全、可信的产品与使用环境，提供优质、高效、可靠的服务与信息是我们的核心目标。为了维护我们服务的正常运行并保护您或其他用户或公众的合法利益免受损失，我们会收集用于维护产品或服务安全稳定运行的必要信息。</p>
    <p>（2）设备信息与日志信息</p>
    <p>在软件与服务的安全运行过程中，我们会收集你的设备信息和日志信息。设备信息指设备型号、网络标识符、网络接入信息等与设备性能相关的信息，在手机终端中可能被表述为“电话信息”，这不代表 App 会收集通讯内容或您主动输入的隐私信息；日志信息指在你使用服务的过程中生成的服务日志。设备与日志信息也通常用在反作弊和判断帐号是否处于安全的环境中。部分信息也可能被用于广告推送。</p>
    <p>为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、设备标识符(Android如IMEI/MEID、AndroidID、OAID、GAID、IMSI、SIM卡信息(如ICCID)，硬件序列号(SN)，不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同)、网络设备硬件地址(设备MAC地址)、IP地址、WLAN接入点(如SSID，BSSID)、蓝牙(Bluetooth)、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、设备传感器数据(包括:方向传感器、加速度传感器、陀螺仪传感器、线性加速度传感器)。</p>
    <p>为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息、软件列表或运行中的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。</p>
    <p>我们可能使用您的帐户信息、设备信息、服务日志信息以及我们关联方、合作方在获得您授权或依法可以提供的信息，用于判断帐户状态及交易安全、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。</p>
    <div class="secondTitle">1.7 收集、使用个人信息目的变更</div>
    <p>请您了解，随着我们业务的发展，我们可能会对小羊淘券的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次按照法律法规及国家标准的要求以页面提示、交互流程、协议确认等方式另行向您进行告知说明，并征得您的同意。</p>
    <div class="secondTitle">1.8 征得授权同意的例外</div>
    <p>请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息不必事先征得您的授权同意:</p>
    <p><span>1、与国家安全、国防安全有关的；</span></p>
    <p><span>2、与公共安全、公共卫生、重大公共利益有关的；</span></p>
    <p><span>3、与犯罪侦查、起诉、审判和判决执行等有关的；</span></p>
    <p><span>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>
    <p><span>5、所收集的个人信息是个人信息主体自行向社会公众公开的；</span></p>
    <p><span>6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道，政府信息公开等渠道；</span></p>
    <p><span>7、根据您的要求签订合同所必需的；</span></p>
    <p><span>8、用于维护所提供的产品和服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</span></p>
    <p><span>9、为合法的新闻报道所必需的；</span></p>
    <p><span>10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span></p>
    <p><span>11、法律法规规定的其他情形</span></p>
    <p>特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息;当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
    <h3 class="titleOne">二、我们如何共享、转让、公开纰漏您的信息</h3>
    <div class="secondTitle">2.1 共享</div>
    <p><span>（1）我们不会与小羊淘券以外的任何公司、组织和个人共享您的个人信息。但以下情况除外：</span></p>
    <p>1、事先获得您明确的同意或授权</p>
    <p>2、根据适用的法律法规，法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</p>
    <p>3、在法律法规允许的范围内，为维小羊淘券、小羊淘券的关联方或合作伙伴、您或其他小羊淘券用户或社会公众利益、财产或安全免遭损害而有必要提供；</p>
    <p>4、只有共享您的信息。才能实现我们的产品和服务的核心功能或提供您需要的服务；</p>
    <p>5、应您需求为您处理您与他人的纠纷或争议；</p>
    <p>6、符合与您签署的相关政策（包括在线签署的电子政策以及相应的平台规则）或其他的法律文件约定所提供；</p>
    <p>7、基于学术研究面使用；</p>
    <p>8、基于符合法律法规的社会公共利益而使用。</p>
    <p><span>（2）我们可能会将您的个人信息与我们的关联方共享，但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</span></p>
    <p><span>（3）我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span></p>
    <p>我们的合作伙伴包括以下类型</p>
    <p>1、第三方商家。当您通过小羊淘券去第三方平台购买商品或服务时，我们需将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其具体平台购买商品或服务的需求，并促使其可以完成后续的售后服务。如我们与淘宝共享您的订单信息，来为您提供售后服务；</p>
    <p>2、商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理、安全认证等。我们共享这些信息的目的是可以实现我们产品和服务的核心购物功能，比如我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付；</p>
    <p>3、实现广告与推广业务的数据使用，a.广告推送与投放:为实现广告与推广以及相关分析服务，并优化与提升广告与推广的有效触达率，以下合作方需要使用去标识化或匿名化后的设备、网络、渠道信息、位置和标签信息:广告主/推广方，或提供广告与推广以及相关分析服务的合作方;与广告主/推广方合作的提供广告与推广以及相关分析服务的合作方。b.统计分析:进行广告与推广以及提供相关分析服务的合作方可能需要使用您的设备、网络、广告与推广的点击、浏览、展示以及转化数据等信息用于分析、衡量、提升广告与推广服务的有效性。c.合作方对信息的使用:广告与推广合作方可能将上述信息与其合法获取的其他数据相结合，以优化广告与推广投放效果，我们会要求其对信息的使用遵循合法、正当、必要原则，保障用户合法权利不受侵犯。d.留资信息:您在广告主/推广方或其委托的服务提供方所提供的广告与推广展示页中主动填写您的联系方式、地址等信息时，这些信息可能被广告主/推广方直接收集并使用。</p>
    <p>4、实现安全与分析统计的数据使用, 为分析我们产品的使用和表现情况，提升用户使用的体验，我们的合作方可能需要使用该产品来源与使用情况(崩溃、闪退、使用时长等)、设备标识信息、应用总体安装使用情况等信息。</p>
    <p>5、我们会通过应用程序接口（API）、软件工具开发包（SDK）与合作第三方进行必要的数据共享。对合作第三方的基本情况我们会在合作前进行严格的安全影响评估与安全监测。并与合作第三方签署合作协议，约定其应按照合理必要的保密和技术安全措施来保护个人信息。详细的与合作方共享个人信息清单请查看<a :href="sdkurl" class="sdklink">《小羊淘券第三方SDK目录》</a>。</p>
    <div class="secondTitle">2.2 转让</div>
    <p>除非小羊淘券运营主体发生变更，否则小羊淘券不会向任何第三方转让您的个人信息。如小羊淘券运营主体变更导致转让您的个人信息，我们也会严格要求受让主体按照本隐私权政策严格保护您的个人信息。否则，我们将要求该受让主体重新向您征求授权同意。</p>
    <div class="secondTitle">2.3 公开披露</div>
    <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
    <p>1、获得您明确同意后；</p>
    <p>2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，</p>
    <p>我们可能会公开披露您的个人信息。</p>
    <div class="secondTitle">2.4 共享、转让、公开披露个人信息时事先征得授权同意的例外</div>
    <p>根据相关法律法规规定，以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
    <p>1、与我们履行法律法规规定的义务相关的；</p>
    <p>2、与国家安全、国防安全直接相关的；</p>
    <p>3、与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>4、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
    <p>5、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；</p>
    <p>6、个人信息主体自行向社会公众公开个人信息的；</p>
    <p>7、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
    <p>请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。</p>
    <h3 class="titleOne">三、您的个人信息权利</h3>
    <p>在你使用小羊淘券期间，为了您可以更加便捷地查阅、复制、更正、删除你的个人信息，同时保障你撤回对个人信息处理的同意及注销帐号的权利，我们在产品设计中为你提供了相应的操作设置，你可以参考下面的指引进行操作。此外，我们还设置了意见建议渠道，你的意见及请求将会在这里得到及时的处理和反馈。</p>
    <p>请注意，不同操作系统、小羊淘券APP软件的不同版本之间，操作设置可能存在差异；此外，为了优化你的使用体验，我们也可能对操作设置进行调整。故如下指引仅供参考，若你对行使相关权利的方式及途径仍有任何疑问的，你可以通过本政策第八条披露的方式与我们联系。</p>
    <div class="secondTitle">3.1 访问和更正您的个人信息</div>
    <p>除法律法规规定外，您有权随时访问和更正您的个人信息。如果您想行使数据访问权，可以通过以下方式自行访问。</p>
    <p>1、账户信息：如果您希望访问和编辑您的账户中的信息，您可以通过访问“小羊淘券APP—我的—设置”执行此类操作。</p>
    <p>2、支付宝提现账号信息：如果你想查看和编辑支付宝账号信息，您可以通过访问小羊淘券APP—我的—去提现—收益中心执行此类操作</p>
    <p>3、搜索信息：您可以在小羊淘券APP首页界面的搜索栏中访问或清除您的搜索历史记录。</p>
    <p>4、对于您在使用我们产品和服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请在商务合作界面添加我们专属客服并详细说明您的需求。</p>
    <p>5、另外，如您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。您可以通过“ 访问和更正您的个人信息”中罗列的方式提出更正申请。</p>
    <p>6、如果您无法通过上述途径访问或更正这些个人信息，您可以随时通过小羊淘券的意见建议界面联系我们，也可通过商务合作页面添加专属的客服微信与我们联系。我们将在15天内回复您的访问请求。</p>
    <div class="secondTitle">3.2 删除个人信息</div>
    <p style="font-weight: bold;">你可以通过前款“访问和更正您的个人信息”中罗列的方式和渠道，操作删除您的信息</p>
    <p>您在我们的产品和服务页面中可以直接清除或删除的信息，包括搜索历史信息、浏览信息，在以下情形中，您可以向我们提出删除个人信息的请求：</p>
    <p>1、如果我们处理个人信息的行为违反法律法规；</p>
    <p>2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
    <p>3、如果我们处理个人信息的行为严重违反了与您的约定；</p>
    <p>4、如果您不再使用我们的产品或服务，或您主动注销了账号；</p>
    <p>5、如果我们永久不再为您提供产品或服务。</p>
    <p>若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定或这些主体已独立获得您的授权）。</p>
    <div class="secondTitle">3.3 改变您授权同意的范围或撤回您的授权</div>
    <p>您可以通过删除信息、关闭设备功能、您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。您也可以通过设备的系统设置功能关闭位置信息(地理位置)、相册（图片库）改变授权范围和撤回授权。</p>
    <p>请您理解，每个业务功能需要一些基本的个人信息才能得以完成，撤回授权后我们将不再收集与这些权限相关的信息，但不影响我们此前基于您的授权进行的个人信息处理活动。</p>
    <div class="secondTitle">3.4 注销账户</div>
    <p>您可以在我们的产品中（我的-设置-账号安全-账号注销）直接申请注销账户。关于您注销账户的方式以及您应满足的条件，账号注销规则详见小羊淘券APP“账户注销”页面。</p>
    <p>在您申请账户注销时，我们需要您提供注册手机号码及验证码以核验您的注册身份，在您主动注销账户之后，我们将停止为您提供产品或服务，并根据您的要求，删除您的个人信息，或对其进行匿名化处理，但法律法规另有规定的除外。</p>
    <div class="secondTitle">3.5 逝者近亲属的权利</div>
    <p>如小羊淘券用户不幸逝世，其近亲属可通过本政策第8条披露的方式与我们联系，以获取相关指引来行使法律规定的合法、正当权益。</p>
    <div class="secondTitle">3.6 响应您的上述请求</div>
    <p>保障您的账户安全，您向我们提出上述请求时，我们可能会先要求您验证您的身份（如要求您提供书面请求或其他方式），然后再处理您的请求。对于您向我们提出的上述请求，我们将在15个工作日内作出答复。</p>
    <p>对于您的合理请求，我们原则上不收取费用。但对多次重复、超出合理限度的请求，我们将视情况收取一定的成本费用。对于无端重复、需要过多技术手段（例如：需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。</p>
    <p>在以下情形中，我们将无法响应您的请求：</p>
    <p>1、与我们履行法律法规的义务相关的；</p>
    <p>2、与国家安全、国防安全直接相关的；</p>
    <p>3、与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>4、与刑事侦查、起诉、审批或执行判决等直接相关的；</p>
    <p>5、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的。</p>
    <h3 class="titleOne">四、我们如何存储个人信息</h3>
    <div class="secondTitle">4.1 存储地点</div>
    <p>我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，会严格中国的相关法律、监管政策，并会遵循相关国家规定或者征求您的同意。</p>
    <div class="secondTitle">4.2 存储期限</div>
    <p>我们仅在为提供小羊淘券及服务之目的所必需的期间内保留您的个人信息，例如：</p>
    <p>手机号码：当您使用手机号码注册登录时，我们需要持续保留您的手机号码，以便于向您提供正常的服务、应对您可能的查询和客诉并保障您的帐号和系统安全。</p>
    <p>您通过第三方电商平台购买的商品信息、以及提现记录等信息在未注销帐号期间，我们会保留相关信息用于向您提供持续的业务功能。如果您注销帐户、主动删除个人信息或超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但以下情况除外：</p>
    <p>遵从法律法规有关信息留存的要求。（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。出于财务、审计、争议解决等目的需要合理延长期限的。</p>
    <h3 class="titleOne">五、您如何管理个人信息</h3>
    <p>您可以通过多种方式管理您的个人信息，例如自行清除您的浏览记录、收藏记录、更新您的个人资料以及注销您的账户。</p>
    <p>如果您在管理您的个人信息时遇到问题，您可以与我们联系。但也请您理解，我们可能会因为履行法律法规义务或者我们提供服务必要性的原因无法完全满足您的要求。</p>
    <h3 class="titleOne">六、未成年人条款</h3>
    <p>我们的产品和服务主要面向成人。若您是未满18周岁的未成年人，在使用小羊淘券及相关服务前，应在您的父母或者其他监护人监护、指导下共同阅读并同意隐私政策。</p>
    <p>（1）我们非常重视对未成年人个人信息的保护。小羊淘券会根据国家相关法律法规的规定保护未成年人的个人信息。</p>
    <p>（2）对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</p>
    <p>（3）尽管当地法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。若您是未满14周岁的未成年人的监护人，在使用小羊淘券及相关服务前，应为您的被监护人阅读并同意本隐私政策。</p>
    <p>（4）如果我们发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。</p>
    <h3 class="titleOne">七、隐私政策的修正和通知</h3>
    <p>（1）为了给您提供更好的服务，汽水音乐产品和服务将不时更新与变化，我们会适时对本隐私政策进行修订。未经您明确同意，我们不会削减您依据当前生效的隐私政策所应享受的权利。</p>
    <p>（2）本隐私政策更新后，我们会在小羊淘券发出更新版本，并通过站内信或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。</p>
    <p>（3）我们也可能通过功能更新，帮助您更好地管理您的个人信息，请您留意相关的功能说明。</p>
    <h3 class="titleOne">八、联系我们</h3>
    <p>如果你认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，以及有任何疑问和建议，您可以通过以下方式联系我们，我们核查后将在15个工作日内回复您的请求与反馈：</p>
    <p>1、您可以在小羊淘券客户端内【我的】-【意见建议】页面，与我们联系。</p>
      <p>2、您可以将问题发送邮件至（yzy@xiaoyangtaoquan.com）</p>
        <p>3、您可以在【我的】-【商务合作】页面加小羊淘券专属客服企业微信，我们也会有专人一对一进行服务。</p>
          <p>如果您对我们的服务回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p>











    
    
    
    
    
    
    
    
    
    <!-- <h3>一、我们如何收集、使用信息</h3>
    <h3>（一）您须授权我们收集和使用您个人信息的情形</h3>
    <div class="secondTitle">1、基于网上购物所必须的功能</div>
    <div class="secondTitle" style="margin-top:0.7em">（1）用户注册</div>
      <p><span>【设置帐号信息】</span>当你注册帐号，需要设置帐号头像、昵称、密码以及绑定您的<span>支付宝账号/银行账户</span>（用于账户提现）相关信息。你可以选择填写性别、生日、地区及个人介绍等信息。</p>
      <p><span>【使用第三方帐号登录】</span>当你使用第三方帐号登录，你将授权我们获取你在第三方平台注册的公开信息（<span>用户名、手机号、头像、昵称、地区、性别</span>（具体以第三方登录服务提供的信息为准）以及<span>身份验证信息</span>）。</p>
    <div class="secondTitle">（2）商品/服务信息展示和搜索</div>
      <p><span>【设备信息与日志信息】</span>在软件与服务的安全运行过程中，我们会收集你的设备信息和日志信息。设备信息指设备型号、网络标识符、网络接入信息等与设备性能相关的信息，在手机终端中可能被表述为“电话信息”，这不代表 App 会收集通讯内容或您主动输入的隐私信息；日志信息指在你使用服务的过程中生成的服务日志。设备与日志信息也通常用在反作弊和判断帐号是否处于安全的环境中。部分信息也可能被用于广告推送。
      <p><span>【分享、识别】</span>在你分享或接收被分享的信息、商品搜索等情形下，我们需要访问你的<span>剪切板</span>，读取其中包含的口令、分享码、链接，以实现跳转、分享、活动联动等功能或服务。</p>
      <p><span>【统计分析】</span>我们可能需要将应用来源渠道信息通过<span>剪切板</span>进行统计，在统计过程中我们不会将你写入剪切板的个人隐私信息回传。</p>
    <div class="secondTitle">（3）为您提供收藏、关注与分享功能</div>
      <p>【【使用行为】你使用时，我们将记录你的关注、收藏、搜索、浏览、分享等行为。上述信息是对你使用小羊淘券各项功能的实际情况的记录。</p>
    <div>（4）交易订单及服务</div>
      <p>【为了便于您了解查询部分订单信息并对部分订单信息进行管理，我们会收集您在使用我们服务过程中产品的部分订单信息（包括：订单来源（平台）、订单创建时间、订单金额、所购买的商品及/或服务信息、具体订单号、返利情况、订单状态）用于您对订单进行查询、共享、向您提供客服与售后服务及其他我们明确告知的目的。</p>
      <div>2、基于开启内部审计、数据分析和研究和个性化推荐</div>
      <p>【我们可能会收集您的订单信息、浏览信息、您的兴趣爱好进行数据分析，用来将您感兴趣的商品或服务信息展示给您；为您提供个性化服务或内容；或在您搜索时向您展示您可能希望找到的商品。例如在小羊淘券APP客户端可能设有专门的模块“猜你喜欢”等功能。</p>
      <div>3、基于安全管理</div>
      <p>【为保障您使用我们的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，我们可能会通过了解<span>您的浏览信息、订单信息、您常用的软件信息、设备信息、安装应用软件列表、设备MAC地址、操作系统类型、软件列表唯一设备识别码</span>手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”））；我们也会收集您的设备信息（包括设备型号、操作系统版本、设备设置、MAC地址及IMEI、IDFA、OAID等设备标识符、设备环境、移动应用列表软硬件特征信息）对于小羊淘券使用问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。</p>
    <h3>（二）您可选择是否授权我们收集和使用您的个人信息的情形</h3>
      <p>1、<span>如果您不提供这些个人信息，您依然可以进行网上购物，但您可能无法使用这些可以为您所带来购物乐趣的附加功能或在购买某些商品时需要重复填写一些信息。</span>这些附加功能包括：</p>
      <p><span>（1）关于位置信息：</span>地理位置信息属于敏感信息，拒绝提供该信息会影响您在第三方平台享受的部分服务（首页-热门-省钱外卖、首页-生活-携程/飞猪、首页-生活-加油优惠需要您提供具体位置信息来进行定位、导航服务）。</p>
      <p><span>（2）基于相机/摄像头的附加功能：</span>您可以在开启相机/摄像头权限后使用该功能进行拍摄照片或视频用于商品的照片或视频素材下载、分享或设置账户头像及图搜功能。您还可以通过上传带有您账户信息的照片来实现客服对您的身份核验（我的-联系我们-专属客服）。</p>
      <p><span>（3）基于客服功能的附加功能：</span>在您使用客服功能时，我们可能还会需要收集您的如下个人敏感信息：<span>联系方式（您与我们联系时使用的电话号码/电子邮箱或您向我们主动提供的其他联系方式）、您与我们的沟通信息（包括文字/图片/音视频）、与您需求相关联的其他必要信息。</span>在此过程中您需开启<span>麦克风权限（仅限IOS申请权限）</span>。我们收集这些信息是为了调查事实与帮助您解决问题。
      <p>【当您与我们的人工客服互动时，如使用到上传视频功能则需要您开启麦克风权限（我的-联系我们-专属客服-人工客服）。</p>
      <p><span>（4）基于日历的附加服务：</span>在您开启我们可读取/写入您日历的权限后，我们将收集您的日历信息用于向您提供爆品推荐（首页-爆品推荐）、外卖点餐（首页-省钱外卖icon）这些服务的提醒。</p>
      <p><span>（5）基于本地存储的附加功能：</span>为了提供更好的服务，我们在系统运行时需要记录相关信息到您手机的外部存储器中，需要您开启可读取/写入您存储的权限。</p>
      <p>系统权限与调用情况具体可参见<a>《系统权限调用与使用清单》</a>。</p>
    <h3>二、我们如何共享您的信息</h3>
      <p>请您理解，我们无法单独向您提供全部的服务，很多时候我们还需要同我们的关联公司以及其他的服务供应商一起为您提供服务，因此，为满足您提供上述服务的必要，我们需要向我们的关联公司、供应商、合作伙伴以及其他第三方以共享或委托处理的方式提供您的部分个人信息。我们会采取必要的技术和管理措施保护您的个人信息安全。</p>
      <p>您可以通过<a>《个人信息第三方共享清单》</a>查看第三方SDK收集和使用您个人信息的情况。</p>
    <h3>三、您如何管理您的信息</h3>
      <p>您可以通过多种方式管理您的个人信息，例如自行清除您的浏览记录、更新您的个人资料以及注销您的账户。</p>
      <p>如果您在管理您的个人信息时遇到问题，您可以与我们联系。但也请您理解，我们可能会因为履行法律法规义务或者我们提供服务必要性的原因无法完全满足您的要求。</p>
      <h3>四、未成年人的个人信息保护</h3>
      <p>我们的产品和服务主要面向成人。<span>若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您家长或法定监护人的书面同意。</span></p>
      <p>1、我们非常重视对未成年人个人信息的保护。小羊淘券会根据国家相关法律法规的规定保护未成年人的个人信息。</p>
      <p>2、对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。</p>
      <p>3、尽管当地法律和习俗对儿童的定义不同，但<span>我们将不满14周岁的任何人均视为儿童。</span></p>
      <p>4、如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。</p>
      <h3>五、如何联系我们</h3>
      <p>1、如您对本个人信息保护政策或您在使用小羊淘券产品或服务时，就您的个人信息的收集、使用、共享、查询、删除、更正等相关事宜有任何问题、意见或建议，或您在使用产品或服务时遇到哪些问题，您都可以通过访问小羊淘券APP在线客服系统或发送邮件至13352593316@163.com与我们联系。</p>
      <p>2、我们还设立了个人信息保护专职部门，您可以通过发送邮件至13352593316@163.com的方式与我们联系。</p>
      <p>3、特别提醒您，如您反馈的问题涉及您的个人信息，尤其是您的个人敏感信息，请您不要直接在电话或邮件中进行透露，我们将会在确保您个人信息安全的情况下与您进一步沟通。</p>
      <p>4、我们将在收到您的反馈并验证您身份后15个工作日内答复您的相关请求。</p>
      <p>如果您对我们的服务回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</p> -->
</div>
</template>

<script>

export default {
  name: 'Pipp',
  data(){
    return{
      version_code:10000,
      sdkurl:"https://www.xiaoyangtaoquan.com/xiaoyang_shop/v1/sdklist"
    }
  },
  created(){
    // if(this.$route.query.version_code){
    //     this.version_code = this.$route.query.version_code
        this.sdkurl = "https://www.xiaoyangtaoquan.com/xiaoyang_shop/v1/sdklist?version_code="+99999999
    // }
  },
  methods:{
    gosdk(){
      this.$router.push("/xiaoyang_shop/v1/sdklist")
    }
  }
}
</script>
<style scoped>
  .home{
    padding: 12px;
    text-align: left;
    color: #000;
  }
  .home p{
    text-indent: 2em;
    margin: 5px 0;
    word-wrap: break-word;
    white-space: break-spaces;
    word-break: break-all;
    line-height: 24px;
    font-size: 14px;
  }
  .home span{
    font-weight: bold;
  }
  .alignleft{
    text-align: left;
  }
  .home ul {
    margin: 0px;
    list-style: none;

  }
  .home ul li{
    text-align: left;
    line-height: 24px;
    font-size: 14px;
    word-wrap: break-word;
      white-space: break-spaces;
      word-break: break-all;
  }
  .aligncenter{
    text-align: center;
  }
  .fontWeight{
    font-weight: bold;
  }
  .secondTitle{
    font-weight: bold;
    font-size: 14px;
    text-indent: 2em;
  }
  .titleOne{
    margin: 10px 0;
    text-indent: 0em;
  }
  .sdklink{
    color:#0000ee;
    text-decoration: none;
    cursor:pointer
  }
</style>
