<template>
    <div class="home">
        <p class="fontWeight">提现须知</p>
        <p>1.适用范围：</p>
        <p>(1)本规则仅适用于【可提现】账户。</p>
        <p>2.提现规则:</p>
        <p>(1)提现时间：订单确认收货次月25日可提现。由于财务审核、遇节假日、不可抗力等原因，部分时间段将无法支持提现操作，具体请以提现页面信息为准。</p>
        <p>(2)提现限额：单笔提现不能低于人民币{{ min }}元，不能超过{{ max }}元，具体请以提现页面信息为准。</p>
        <p>到账时间：一般发起提现申请后48小时内到账您在小羊淘券绑定的支付宝账户上。</p>
        <p>提现次数：当日最多不超过{{ times }}次。</p>
        <p>(3)提现账号绑定：一个小羊淘券账号只可以绑定一个支付宝账号。</p>
        <p>(4)账号进行提现：需要确认您填写的支付宝账号信息是真实有效的，否则有可能会导致您提现失败。</p>
        <p>(5)提现条件：您应满足小羊淘券提现的前置条件，并根据提现页面提示的流程、规范进行提现操作。如因您自身原因导致提现失败(包括但不限于未按提现要求操作未绑定支付宝账 号、实名认证信息、预留的收款账号信息不一致等)，您应自行承担因此导致的损失(包括但不限于因打款失败导致资金退回产生的渠道手续费，或打款至他人账户导致无法追回资金等)。</p>
        <p>3.其他:</p>
        <p>承诺与保证:您理解并同意，如您存在违反法律法规、《小羊淘券用户协议》或通过任何不正当、违反诚实信用原则的方式(包括但不限于提供虚假信息、反复注销注册、一人多号或利用机器人软件/蜘蛛软件等技术手段)进行提现，小羊淘券有权单方面中止/终止向您提供全部或部分小羊淘券服务(包括但不限于获取小羊淘券收益的资格)，并有权拒绝您的小羊淘券提现申请或要求您返还已提现小羊淘券收益。</p>
    </div>
</template>
  
  <script>
  
  export default {
    name: 'NodesToWithdrawal',
    data(){
      return{
        max:'2000',
        min:'5',
        times:'3'
      }
    },
    created(){
        if(this.$route.query.max){
            this.max = this.$route.query.max
        }
        if(this.$route.query.min){
            this.min = this.$route.query.min
        }
        if(this.$route.query.times){
            this.times = this.$route.query.times
        }
    },
  }
  </script>
  <style scoped>
    .home{
      padding: 12px;
      text-align: left;
      color: #000;
    }
    .home p{
      /* text-indent: 2em; */
      margin: 5px 0;
      word-wrap: break-word;
      white-space: break-spaces;
      word-break: break-all;
      line-height: 24px;
      font-size: 14px;
      text-align: justify;
    }
    .fontWeight{
      font-weight: bold;
    }
  </style>
  