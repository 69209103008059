<template>
  <div class="home">
    <h3 class="aligncenter">“小羊淘券”用户协议</h3>
    <p style="text-indent: 0em;">更新时间：2024年【8】月【11】日</p>
    <p style="text-indent: 0em;">生效时间：2024年【8】月【11】日</p>
    <h3>特别提示：</h3>
    <p>“小羊淘券”软件，系指大庆一只羊科技有限公司及其关联方(以下简称“一只羊科技”或“公司”) 合法拥有并运营的、标注名称为“小羊淘券”的客户端应用程序。“小羊淘券”在此特别提醒：在您注册成为用户（以下简称“用户”或“您”）之前，请认真阅读本《用户协议》（以下简称“本协议”），本协议是您与大庆一只羊科技就您下载、安装、注册、登录、使用(以下统称“使用”)“小羊淘券”软件，并获得“小羊淘券”软件提供的相关服务所订立的协议，具有合同效力。</p>
    <p>为了更好地为您提供服务，请您在开始使用“小羊淘券”软件及相关服务之前，认真阅读、理解并遵守为本协议，特别是免除或者限制责任的条款、法律适用和争议解决条款等以粗体划线重点标识的条款。对用户权利进行限制的条款以及约定争议解决方式、司法管辖的条款。您有权同意或者不同意本协议。<span>其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您未满18周岁，请您在法定监护人的陪同下阅读并充分理解本协议，并征得法定监护人的同意后使用“小羊淘券”软件及相关服务。</span></p>
    <p>如您决定注册使用“小羊淘券”，完成我们的注册流程并通过点击同意的形式在线签署本协议即表示您已充分理解并同意接受本协议所有及任何条款的约束，并与“小羊淘券”就本协议达成一致，成为“小羊淘券”用户。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，这将导致公司无法为您提供完整的产品和服务，您也可以选择停止使用。</p>
    <p><span>根据国家法律法规变化或“小羊淘券”运营需要，本协议可由“小羊淘券”不时地更新或修改，修改后的协议条款经提前7日公示即生效并代替原来的协议条款，相关内容将公布在本软件等客户端上或以其他方式通知你，您可随时查阅最新版协议条款。在“小羊淘券”修改协议条款后，如果您不接受或不理解本协议任一修改后的条款的，或者对本协议内容有任何疑问、意见或建议，您可通过登录小羊淘券客户端内的“意见与建议”页面或发送邮件至<i style="font-style: normal;text-decoration: underline;">yzy@xiaoyangtaoquan.com</i>与公司联系。如您咨询后仍不接受本协议的，请立即停止使用“小羊淘券”提供的服务，您继续使用“小羊淘券”提供的服务将被视为对本协议全部条款已充分理解并接受修改后的协议。</span></p>
    <p><span>目录</span></p>
    <p><span>一、平台服务</span></p>
    <p><span>二、用户注册、使用与注销</span></p>
    <p><span>三、用户个人信息保护</span></p>
    <p><span>四、使用规范</span></p>
    <p><span>五、网络安全保护</span></p>
    <p><span>六、违规用户处理</span></p>
    <p><span>七、广告</span></p>
    <p><span>八、税收</span></p>
    <p><span>九、未成年人使用条款</span></p>
    <p><span>十、服务变更、中断与终止</span></p>
    <p><span>十一、法律适用于争议解决</span></p>
    <p><span>十二、免责声明</span></p>
    <p><span>十三、其他</span></p>
    <h3 class="titleOne">一、平台服务</h3>
    <p>1.本协议是由大庆一只羊科技有限公司（简称“小羊淘券”或者“我们”）与用户（或称为“您”）就您使用小羊淘券产品和服务所做的权利义务的约定。</p>
    <p>2.您使用小羊淘券软件及相关服务，可以通过公司已授权的第三方下载等方式获取小羊淘券客户端应用程序。我们为不同的终端设备开发不同的应用程序版本，请您根据实际终端设备状况获取、下载、安装小羊淘券官方版本。<span>若您并非从公司或经公司授权的第三方获取小羊淘券软件的，公司无法保证非官方版本的小羊淘券软件能够正常使用，您因此遭受的损失与公司无关。</span></p>
    <p>3.除在APP中明确表明为“自营平台项目”（或类似表述）小羊淘券仅作为用户提供<span>淘宝</span>（即由浙江淘宝网络有限公司经营的域名为taobao.com的网络零售网站平台）、<span>天猫</span>（即由浙江天猫网络有限公司经营的域名为tmall.com的网络零售网站平台）、<span>京东</span>（即北京京东世纪贸易有限公司经营的域名为jd.com的网络零售网站平台）、<span>拼多多</span>（即由上海寻梦信息技术有限公司经营的域名为pinduoduo.com的网络零售网站平台）、<span>唯品会</span>（即由广州唯品会电子商务有限公司经营的域名为vip.com的网络零售网站平台）<span>等购物平台（“外部电商平台”）中的商家提供产品及产品信息的展示平台。</span></p>
    <p>4.小羊淘券平台为用户提供以下平台服务：</p>
    <p>（1）为您展示商品或服务</p>
    <p>小羊淘券会为您展示商品或服务的相关信息，供您浏览、了解所需的商品或服务。</p>
    <p>（2）为您推送商品或服务</p>
    <p>小羊淘券会为您制定更符合您喜好和需求的个性化推送商品或服务的页面展示和搜索结果，提升您的使用体验。</p>
    <p>（3）为您在购物平台上购买商品或服务提供优惠通道</p>
    <p>当您欲购买在小羊淘券上浏览的商品或服务时，您通过小羊淘券领取优惠券或其他福利后，直接进入销售该商品或服务的淘宝、天猫店铺和其他商家店铺并完成下单流程。</p>
    <p>（4）为您提供持续、稳定的技术平台服务</p>
    <p>无论用户是否登录APP，小羊淘券将持续、稳定的为您提供技术平台服务，直至您以书面告知或注销账户的方式停止使用小羊淘券服务。</p>
    <p><span>5.小羊淘券为技术服务平台，用户购买小羊淘券上展示和推送的商品或者服务时，需按照外部电商平台要求另行签署相关协议，所形成的买卖交易关系是在外部电商平台商家与用户之间建立，所购商品或者服务直接由外部电商平台商家向用户提供，包括但不限于商品或者服务的供应、运送、储存、交付、付款、包装、售后服务等。</span></p>
    <p>6.用户与外部电商平台商家之间因商品或服务交易发生争议或其他纠纷的，包括但不限于投诉、起诉、举报及税赋等，由用户与外部电商平台商家双方自行解决。经用户申请，小羊淘券可以为双方提供第三方协助调解纠纷服务。</p>
    <p>7.使用小羊淘券或通过小羊淘券相关链接产生的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱小羊淘券及外部电商平台正常交易秩序的行为。若您严重违背社会公德、提供虚假注册身份信息、经外部电商平台或我们判定认为存在恶意退货或不合常理的高退货率以及其他不符合正常消费习惯的情形、或经判定认为属于损害小羊淘券及外部平台不正当行为时，基于维护交易秩序及交易安全的需要，小羊淘券发现上述情形时有权主动执行对订单不予结算、冻结该用户账号等操作。</p>
    <p>8.您使用小羊淘券需自行准备手机等相关终端设备。为实现小羊淘券的全部功能，您需将终端设备联网，您应自行承担所需要的上网费、流量费等费用。</p>
    <p>9.您可根据需要自行使用小羊淘券软件及相关服务或更新小羊淘券版本，如您不再需要使用小羊淘券软件及相关服务，您也可自行卸载相应的应用程序软件。</p>
    <p>10.为更好的提升用户体验及服务，公司将不定期提供“小羊淘券软件及相关服务的更新或改变(包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等)，您可根据需要自行选择是否更新相应的版本。为保证小羊淘券软件及相关服务安全、提升用户服务，小羊淘券软件及相关服务部分或全部更新后，公司将在可行情况下以适当方式(包括但不限于系统提示、公告等)提示您，您有权选择接受更新后版本;如您选择不作更新，则小羊淘券软件及相关服务的部分功能将受到限制或不能正常使用。</p>
    <p>11.为了更好的服务用户，小羊淘券可能会不定时举办各类活动，如果您选择参加活动，应当认真查阅各类活动另行制定的规则。您同意不会采取作弊、利用系统漏洞等手段扰乱小羊淘券秩序。</p>
    <h3 class="titleOne">二、用户注册、使用与注销</h3>
    <p>1.您确认，在您完成注册程序并实际使用服务时，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，用户一旦注册成功，我们将推定您具有相应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因使用本APP而可能产生的一切法律后果。若您未取得监护人的同意，监护人可以通过联系小羊淘券APP官方公布的联系方式通知小羊淘券处理相关账号，小羊淘券有权对相关账号的功能、使用进行限制，包括但不限于浏览、搜索、提现等功能。</p>
    <p>2.用户应提供本人正在合法有效使用的手机号码进行“小羊淘券”账号注册，并且您同意小羊淘券将您手机号码及手机设备识别码等作为用户身份识别信息。该号码是我们与您联系的重要渠道，如发生变更，应及时通知我们。否则，可能会因无法有效联系到您，或无法及时处理与您相关的争议等事项而影响您的相关权益。</p>
    <p><span>3.您理解并承诺，您所设置的账号不得违反国家法律法规及公司的相关规则，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为，您的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义(包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式)开设账号，不得恶意注册小羊淘券账号(包括但不限于频繁注册、批量注册账号等行为)。公司有权对您提交的信息进行审核。</span></p>
    <p>4.为向您提供更细致、贴心的服务，您允许小羊淘券及授权、认可的第三方向您注册账号时填写的手机号码推送您可能感兴趣的广告宣传信息，发送促销优惠等商业性信息短信，对您进行电话回访，向您提供活动通知等服务；如您不需要此类服务，您有权根据短信提供的相应退订方式进行退订或通过联系客服等我们提供的其他方式进行退订或关闭。</p>
    <p><span>5.用户注册账号所提供和使用的信息应当真实、有效、合法。注册成功后，小羊淘券将为您配置账户，该账户之所有权小羊淘券所有，您在本协议有效期内仅享有使用权。由于账户关联用户的个人信息及小羊淘券商业信息，您的账号仅限于您本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果公司发现或者有合理理由认为使用者并非账号初始注册人，为保障账号安全，公司有权立即暂停或终止向该注册账号提供服务，并有权注销该账号，而无需承担任何法律责任，由此产生的包括但不限于您使用本服务产生的全部数据、信息被清空、丢失等的损失，您应自行承担。</span></p>
    <p>6.小羊淘券有权根据法律法规或政策规定，或根据自行制定的规范或规则，对违规、不当使用账户等行为予以限制或予以注销，如小羊淘券判断您的账户可能涉及信息泄漏及/或影响小羊淘券信息安全或者正常运营的及/或侵害或影响其他用户权益的，小羊淘券可拒绝向您提供服务或终止本协议。</p>
    <p>7.用户可以申请注销已注册的账户，您仅能申请注销您本人的账号，并依照小羊淘券的流程进行注销，并且您仍应对您在注销账号前且使用小羊淘券服务期间的行为承担相应责任，如您需要注销您的小羊淘券账号，请打开小羊淘券客户端，在【我的】-【设置】-【账号与安全】-【注销账号】按提示进行注销，我们将核实您的身份、要求您完成未完成的交易、完成资金结算等（例如已充值或已获得的虚拟资产等）后为您提供帐户注销服务。在账户注销完成后，小羊淘券不在为您提供任何服务，同时您的个人信息我们会使其在前台系统保持不可检索、访问的状态，或对其进行匿名化处理。您知晓，根据法律法规规定，相关交易信息保存时间应自交易完成之日起不少于三年。</p>
    <p>8.为使您更好地使用小羊淘券的各项服务，并保障您的账户安全，我们将按照相关法律法规规定要求您完成实名认证。如涉及第三方支付、资金结算或其他可能需要您提供银行卡信息的业务，您应当保证提供的银行帐户信息或第三方支付帐户信息真实、有效、合法，且属于您本人所有，我们及关联公司或我们合作的第三方支付机构将根据您提供的银行帐户信息或第三方支付帐户信息与您进行资金结算。您提供的银行帐户信息或第三方支付帐户信息错误或您拒绝提供，您应当理解您将无法使用本APP部分功能、第三方支付机构提供的支付功能以及您将无法获得结算资金。您理解并同意如因您未能提供有效的上述信息导致资金无法结算时，该部分资金不会因为未支付给您而产生利息。我们可以向您发送通知以便于您可以及时提供信息，若超出一定时间未得到回复，我们有权收回该部分结算资金自行处理。</p>
    <p>9.如发现任何未经您授权使用您的账户登录小羊淘券的情形，我们建议您第一时间与我们联系，但您应理解小羊淘券对任何请求采取行动均需要合理时间，且小羊淘券所采取的行动可能无法避免或者阻止侵害结果的形成或者扩大，除小羊淘券存在法定过错外，小羊淘券不对该侵害结果及扩大承担任何责任。</p>
    <p><span>10.您理解并同意，为了充分使用账号资源，如您在注册后未及时进行初次登录使用或连续超过两个月未登录账号使用等情形，公司有权收回你您的账号。如您的账号被收回，您可能无法通过您此前持有的账号登录并使用小羊淘券软件及相关服务，您该账号下保存的任何个性化设置和使用记录将无法恢复。在收回您的账号之前，公司将以适当的方式向您作出提示，如您在收到相关提示后一定期限内仍未登录、使用账号，公司将进行账号收回。</span></p>
    <p><span>11.如您的账号被收回，您可以通过注册新的账号登录、使用小羊淘券软件及相关服务。您注册新账号并登录、使用的行为仍受到本协议相关条款的约束。</span></p>
    <h3 class="titleOne">三、用户个人信息保护</h3>
    <p>公司与您一同致力于您个人信息 (即能够独立或与其他信息结合后识别用户身份的信息) 的保护，保护用户个人信息是公司的基本原则之一。在使用小羊淘券软件及相关服务的过程中，您可能需要提供您的个人信息 (包括但不限于电话号码、位置信息等)，以便公司向您提供更好的服务和相应的技术支持。公司可能会根据推荐算法向您个性化推荐内容，我们不断完善与探索推荐系统的优化方案，并依法保证您对个性化推荐的自主选择及控制权。公司将依法保护您浏览、修改、删除相关个人信息以及撤回授权的权利，并将运用加密技术、匿名化处理等其他与小羊淘券软件及相关服务相匹配的技术措施及其他安全措施保护您的个人信息，更多关于用户个人信息保护的内容，请参见《小羊淘券隐私政策》</p>
    <h3 class="titleOne">四、使用规范</h3>
    <p>1.用户注册和使用本APP过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等用户所发送、回复或自动回复消息和相关链接页面，以及其他使用用户账号或本APP所产生的内容均不得违反现行法律、法规、规章、政策规定；如果用户在境外使用本APP，应同时遵守所在地或相关国家（地区）的法律法规。</p>
    <p>2.用户不得利用“小羊淘券”账号或本APP制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：</p>
    <p>（1）反对宪法所确定的基本原则的；</p>
    <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>（3）损害国家荣誉和利益的；</p>
    <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
    <p>（9）含有法律、行政法规禁止的其他内容的信息。</p>
    <p>3.用户不得利用“小羊淘券”账号或本APP制作、上载、复制、发布、传播如下干扰“小羊淘券”正常运营，以及侵犯其他用户或第三方合法权益的内容：</p>
    <p>（1）含有任何性或性暗示的；</p>
    <p>（2）含有辱骂、恐吓、威胁内容的；</p>
    <p>（3）含有骚扰、垃圾广告、恶意信息、诱骗信息的；</p>
    <p>（4）涉及他人隐私、个人信息或资料的；</p>
    <p>（5）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；</p>
    <p>（6）含有其他干扰本APP正常运营和侵犯其他用户或第三方合法权益内容的信息。</p>
    <p>4.用户不得利用“小羊淘券”账号或本APP进行如下行为：</p>
    <p>（1）提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的；</p>
    <p>（2）虚构事实、隐瞒真相以误导、欺骗他人的；</p>
    <p>（3）利用技术手段批量建立虚假账号的；</p>
    <p>（4）利用“小羊淘券”账号或本APP从事任何违法犯罪活动的；</p>
    <p>（5）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；</p>
    <p>（6）制造虚假订单，利用淘宝联盟结算规则漏洞进行售后维权等不正当行为；</p>
    <p>（7）其他违反法律法规规定、侵犯其他用户合法权益、干扰“小羊淘券”正常运营或小羊淘券未明示授权的行为。</p>
    <p>5.小羊淘券提供的本APP中可能包括广告，用户同意在使用过程中显示小羊淘券和第三方供应商、合作伙伴提供的广告。</p>
    <p>6.用户在本APP中或通过本APP所传送、发布的任何内容并不反映或代表，也不得被视为反映或代表小羊淘券的观点、立场；用户不得以小羊淘券的名义传送或发布信息；也不得以易被公众或第三方混淆为小羊淘券官方意见的方式传送或发布信息。</p>
    <p>7.用户须对利用“小羊淘券”账号或本APP传送信息的真实性、合法性、无害性、准确性、有效性等全面负责。如因此给小羊淘券或第三方造成损害的，用户应当依法予以赔偿。基于互联网的特殊性，小羊淘券有权制定各类小羊淘券APP使用管理规范，包括小羊淘券违约推广规则及处罚等。</p>
    <h3 class="titleOne">五、网络安全保护</h3>
    <p>1.您不得使用任何插件、外挂、系统或第三方工具对小羊淘券的正常运行进行干扰、破坏、修改或施加其他影响，包括但不限于使用任何自动化程序、软件或类似工具接入小羊淘券，收集或处理其中信息、内容。</p>
    <p>2.您不得进行任何危害小羊淘券系统安全的行为，亦不得利用小羊淘券从事任何危害计算机网络安全的行为。该等行为包括但不限于：</p>
    <p>（1）非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动；</p>
    <p>（2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</p>
    <p>（3）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；</p>
    <p>（4）使用未经许可的数据或进入未经许可的服务器或账号；</p>
    <p>（5）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</p>
    <p>（6）未经许可，企图探查、扫描、测试小羊淘券系统或网络的弱点或其他实施破坏网络安全的行为；</p>
    <p>（7）企图干涉、破坏小羊淘券系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</p>
    <p>（8）伪造TCP/IP数据包名称或部分名称；</p>
    <p>（9）对小羊淘券进行反向工程、反向汇编、编译或者以其他方式尝试发现小羊淘券的源代码；</p>
    <p>（10）恶意注册账号，包括但不限于频繁、批量注册账号；</p>
    <p>（11）其他可能危害小羊淘券或其他平台计算机网络安全的行为。</p>
    <p>3.未经我们书面许可，任何用户、第三方均不得自行或协助他人对小羊淘券中的信息或内容进行如下行为：</p>
    <p>（1）利用小羊淘券中的信息或内容，用于宣传自身产品、为自身产品增加用户、混淆自身产品与小羊淘券的关系等不正当竞争的目的；</p>
    <p>（2）擅自编辑、整理、改编小样淘券中的信息或内容后，在小羊淘券的源页面以外的渠道或平台进行展示；</p>
    <p>（3）采用技术手段或特殊识别方法，对小羊淘券的信息或内容进行修改、劫持、导流、遮挡、插入、弹窗，或其他导致用户无法正常使用小羊淘券的行为；</p>
    <p>（4）以盗链、冗余盗取、爬虫抓取、模拟下载、深度链接、假冒注册等任何不正当方式盗取、监视、复制、传播、展示、镜像、上传、下载、使用小羊淘券中的信息或内容；</p>
    <p>（5）以隐藏或修改小羊淘券域名、小羊淘券标识、小羊淘券用户信息等方式使用、传播小羊淘券中的信息或内容；</p>
    <p>（6）利用未经授权获取的小羊淘券中的信息或内容用于统计热词、命中率、分类、搜索量、点击率、阅读量等；</p>
    <p>（7）将小羊淘券中的信息或内容用于任何形式的销售和商业使用，或以任何形式向第三方提供或允许第三方使用；</p>
    <p>（8）干扰小羊淘券中的信息或内容的相关数据，包括影响内容播放量，篡改小羊淘券数据等；</p>
    <p>（9）其他非法获取或使用小羊淘券中的信息或内容的行为。</p>
    <p>4.如果我们有合理理由认为您的行为违反或可能违反上述约定的，或您有其他行为导致小羊淘券的信息和内容受到不利影响，或导致小羊淘券用户的权益受损的，我们有权进行处理，包括在不事先通知的情况下终止向您提供服务，并依法追究相关方的法律责任。</p>
    <h3 class="titleOne">六、违规用户处理</h3>
    <p><span>1.如果小羊淘券发现用户有违反法律法规、政策规定、“外部电商平台”管理规定、本协议约定、本平台规则，或被其他用户投诉具有违反法律法规、政策规定、本协议约定、本平台规则的行为，小羊淘券有权依据法律法规、政策、本协议及本平台规则对用户的违法、错误的行为或内容进行处理，并视情节轻重对违规的用户处以包括但不限于警告、账号封禁、功能封禁、账户冻结、不予结算资金等处罚，并将有关违法信息向有关部门报告，用户应承担由此产生的一切法律后果。</span></p>
    <p><span>2.小羊淘券对上述违规内容的违规内容的审查仅为初步表面审查，不对审查结论承担责任；如果用户对处理结果存在异议，则应提供相应的证明文件，并与我们或投诉方沟通或采取法律途径解决争议。</span></p>
    <p><span>3.因用户违反法律法规、政策规定、本协议约定或发生其它过错，导致任何第三方向小羊淘券或其合作方、关联方主张任何索赔、要求的，用户应承担相关责任。如果因此造成小羊淘券或其合作方、关联方承担相关责任或发生其他任何损失的，包括律师费、调查取证费、电子数据公证费、差旅费等，小羊淘券或其合作方、关联方有权要求该用户承担赔偿责任或者向该用户追偿。</span></p>
    <p><span>4.被处理用户如对投诉内容或处理决定有异议，可提交申诉意见，小羊淘券将对申诉进行审查，并自行合理判断决定是否变更处罚措施。对于因投诉所导致的处理，小羊淘券仍有权根据投诉方与用户共同确定的意见或司法机关生效法律文书增加、变更或撤销对用户的处理。</span></p>
    <h3 class="titleOne">七、广告</h3>
    <p><span>1.您理解并同意，在您使用小羊淘券的过程中，我们可能会向您推送信息、发布广告或提供其他相关服务。我们有权在小羊淘券产品及服务的任何页面上通过各种方式展示小羊淘券和/或第三方供应商、合作伙伴的商业广告、商品推广或其他商业信息。</span></p>
    <p><span>2.公司依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您应对依该广告信息进行的交易负责，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失由您自行承担，公司不予承担责任。</span></p>
    <h3 class="titleOne">八、税收</h3>
    <p><span>根据国家法律法规的规定，您在小羊淘券产生的收入或获得的奖励、收益等可能需要缴纳相关税费或办理纳税申报。您理解并同意，我们根据税务机关的要求代扣代缴税款或代为办理纳税申报时，可能需要收集或使用您的个人信息和涉税资料，您应该配合我们积极履行纳税义务。</span></p>
    <h3 class="titleOne">九、未成年人使用条款</h3>
    <p><span>1.若您是未满18周岁的未成年人，您应在监护人指导下认真阅读本协议，经您的监护人同意本协议后，方可使用小羊淘券。若您未取得监护人的同意，监护人可以通过平台公示渠道通知平台处理相关账号，平台有权对相关账号的功能（包括但不限于浏览、搜索、提现等）进行限制。</span></p>
    <p><span>2.我们重视对未成年人个人信息及隐私的保护。我们特别提醒您在填写未成年人个人信息时应加强保护意识并谨慎发布包含未成年人素材的内容。您应当取得权利人同意展示未成年人的肖像、声音等信息，且允许我们依据本协议使用、处理该等与未成年人相关的内容。如相关内容侵犯未成年人权利，我们在收到权利人通知或基于其他保障未成年人合法权益的考虑，有权处理相关内容并视情况通知您。</span></p>
    <p><span>3.我们将与监护人共同努力，保护未成年人身心健康。如果您是监护人，您亦应履行对未成年人的监护义务，关注未成年人网络安全，引导未成年人健康合理使用网络。未成年人应当在其监护人的监督指导下，在合理范围内正确学习使用网络，养成良好上网习惯，避免沉迷虚拟的网络空间。</span></p>
    <p><span>4.青少年用户必须遵守《全国青少年网络文明公约》：</span></p>
    <p><span>（1）要善于网上学习，不浏览不良信息；</span></p>
    <p><span>（2）要诚实友好交流，不侮辱欺诈他人；</span></p>
    <p><span>（3）要增强自护意识，不随意约会网友；</span></p>
    <p><span>（4）要维护网络安全，不破坏网络秩序；</span></p>
    <p><span>（5）要有益身心健康，不沉溺虚拟时空。</span></p>
    <p>5.监护人特别提示</p>
    <p>（1）如您的被监护人使用小羊淘券的，您作为监护人应指导并监督被监护人的注册和使用行为。如您的被监护人申请注册小羊淘券账号，我们有权认为其已取得您的同意。</p>
    <p>（2）如您对保护未成年人权利有其他问题，您可通过意见建议相关页面或发送邮件至yzy@xiaoyangtaoquan.com与我们联系。</p>
    <h3 class="titleOne">十、服务变更、中断与终止</h3>
    <p>1.鉴于网络服务的特殊性，用户同意小羊淘券有权随时变更、中断或终止部分或全部的服务（包括收费服务）。小羊淘券变更、中断或终止的服务，小羊淘券应当在变更、中断或终止之前通知用户，并应向受影响的用户提供等值的替代性的服务；如用户不愿意接受替代性的服务，则用户可以选择停止使用小羊淘券提供的服务。</p>
    <p><span>2.如发生下列任何一种情形，小羊淘券有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：</span></p>
    <p><span>（1）根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；</span></p>
    <p><span>（2）用户违反相关法律法规或本协议的约定；</span></p>
    <p><span>（3）按照法律规定或有权机关的要求；</span></p>
    <p>（4）出于安全的原因或其他必要的情形。</p>
    <h3 class="titleOne">十一、法律适用与争议解决</h3>
    <p>1.本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。</p>
    <p>2.若用户和小羊淘券之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交小羊淘券注册所在地有管辖权的人民法院管辖。</p>
    <p>3.本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
    <p>4.本协议所有条款的标题仅为方便阅读所设，本身并无实际涵义，非对条款的定义、限制、解释或描述其范围或界限，不能作为本协议涵义解释的依据。</p>
    <p>5.新版用户协议生效前，用户的行为应遵循目前生效的协议；新版用户协议生效后，按更新后版本执行。</p>
    <h3 class="titleOne">十二、免责声明</h3>
    <p>1.您理解并同意，小羊淘券软件及相关服务可能会受多种因素的影响或干扰，一只羊科技不保证(包括但不限于)</p>
    <p>(1)小羊淘券完全适合您的所有使用要求；</p>
    <p>(2)小羊淘券不受干扰，及时安全、可靠或不出现错误，您经由小羊淘券取得的任何产品、服务或其他材料符合您的期望；</p>
    <p>(3)小羊淘券软件及相关服务中任何错误都将能得到更正；</p>
    <p>2.对于广告或推广等信息的，您请谨慎对待并自行进行判断，基于前述原因您因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，一只羊科技不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。</p>
    <p>3.您理解并同意，在使用小羊淘券软件及相关服务过程中，可能遇到不可抗力等因素 (不可抗力是指不能预见、不能克服并不能避免的客观事件) ，包括但不限于政府行为、自然灾害、网络原因、计算机病毒、木马或其他恶意程序、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，一只羊科技将努力在第一时间及时修复但因不可抗力给您造成了损失，您同意一只羊科技在法律法规范围内免于承担责任</p>
    <p>4. 一只羊科技依据本协议约定获得处理违法违规内容的权利，该权利不构成一只羊科技的义务或承诺，一只羊科技不能保证及时发现违法行为或进行相应处理</p>
    <p>5.您理解并同意一只羊科技将会尽其商业上的合理努力保障您在本软件及服务中的数据存储安全，但是，一只羊科技并不能就此提供完全保证，包括但不限于以下情形:</p>
    <p>（1）一只羊科技有权数据最小化原则基础上根据实际情况自行决定单个用户在本软件及服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份本软件及服务中的相关数据;</p>
    <p>（2）如果您停止使用本软件及服务或服务被终止或取消，一只羊科技可以从服务器上永久地删除您的数据。服务停止、终止或取消后，一只羊科技没有义务向您返还任何数据。服务停止、终止或取消后，一只羊科技没有义务向您返还任何数据。</p>
    <p>6. 您在使用本软件及服务时，须自行承担如下来自一只羊科技不可掌控的风险内容，包括但不限于</p>
    <p>（1）由于不可抗力因素可能引起的个人信息丢失、泄漏等风险</p>
    <p>（2）您必须选择与所安装终端设备相匹配的软件版本，否则，由于软件与终端设备型号不相匹配所导致的任何问题或损害，均由您自行承担</p>
    <p>（3）您在使用本软件访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由您自行承担</p>
    <p>（4）您发布的内容被他人转发、分享因此等传播可能带来的风险和责任</p>
    <p>（5）由于无线网络信号不稳定、无线网络带宽小等原因，所引起的登录失败、资料同步不完整、页面打开速度慢等风险。</p>
    <p>7.您阅读、理解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，一只羊科技在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证一只羊科技判断完全与司法机关、行政机关的判断一致，如因此产生的后果您已经理解并同意自行承担。</p>
    <p><span>在任何情况下，一只羊科技均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因你使用小羊淘券软件及相关服务而遭受的利润损失，承担责任。公司对你承担的全部责任，无论因何原因或何种行为方式，始终不超过你因使用小羊淘券软件及相关服务而支付给公司的费用(如有)。</span></p>
    <p>您理解并同意:关于小羊淘券软件及相关服务，公司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对小羊淘券软件及相关服务的使用行为应自行承担相应风险。</p>
    <h3 class="titleOne">十三、其他</h3>
    <p>1.本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国法律。倘若本协议之任何约定因与相关法律法规抵触而无效，则这些条款应在不违反法律的前提下按照尽可能接近本协议原条文目的之原则进行重新解释和适用，且本协议其它规定仍应具有完整的效力及效果。</p>
    <p>2.本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和小羊淘券之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交小羊淘券住所地有管辖权的人民法院管辖本协议中的标题仅为方便阅读而设，并不影响本协议中任何约定的含义或解释。</p>
    <p>3.您和我们均是独立的主体，在任何情况下本协议不构成我们对您的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。</p>
    <p>4.除非我们另行声明，小羊淘券的所有产品、技术、软件、程序、数据及其他信息（包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计等）的所有权利（包括版权、商标权、专利权、商业秘密及其他相关权利）均归小羊淘券所有。</p>
    <p>5.本协议的任何条款不论因何种原因无效或不具可执行性，不影响其余条款在用户与小羊淘券双方之间的法律效力。</p>
    <p>6.如您对本协议条款有任何疑问或者需要帮助，可通过yzy@xiaoyangtaoquan.com与我们联系。</p>
























    <!-- <h3 class="aligncenter">“小羊淘券”用户协议</h3>
    <div class="alignleft">更新时间：2024年【2】月【24】日</div>
    <div class="alignleft">生效时间：2024年【2】月【24】日</div>
      <p>感谢您使用小羊淘券提供的服务。本协议是由您与小羊淘券即大庆一只羊科技有限公司及其关联公司（关联公司为小羊淘券全资或控股的在本协议项下为用户提供服务的子公司或孙公司，包括但不限于大庆一只羊科技有限公司等已注册或将来成立的主体，以下统称小羊淘券）之间共同缔结。小羊淘券在此特别提醒，在您注册使用小羊淘券提供的软件、网站、服务（统称为“小羊淘券产品和服务”，包括但不限于适用电脑、移动智能终端的产品及服务）前，请务必审慎阅读本协议的全部条款、充分理解各条款内容后再点击同意，特别是免除或者限制小羊淘券责任的条款、对用户权利限制的条款、法律适用和争议解决条款，这些条款将尽可能以加粗及下划线方式标识。您有权选择同意或者不同意本协议。</p>
      <p>您明白我们与您通过互联网以在线签约的方式签订本协议。如您按照注册页面提示填写信息、点击同意本协议且完成全部注册流程，或者在小羊淘券更新本协议后继续使用小羊淘券提供的服务，即表示您已充分阅读、理解并完全同意本协议，愿意接受本协议所有及任何条款的约束。但法律法规另有强制性规定的，依其规定。</p>
      <p>本协议内容包括协议正文、本协议下述条款明确援引的其他协议、小羊淘券已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。一旦相关内容发生变动，小羊淘券将会通过客户端消息提醒或网站公告等方式提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；如果您继续使用本服务的，则视为您同意对本协议内容所做的修改。</p>
    <div class="secondTitle">一、重要须知</div>
      <p>1.小羊淘券重视未成年人的安全和个人信息保护，注册及使用小羊淘券产品或服务前请确认您是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的监护人应承担因此而导致的一切后果，且小羊淘券有权注销（永久冻结）您的小羊淘券账号，并向您及您的监护人索偿或者追偿。若您不具备前述主体资格，则需要监护人同意您方可注册成为小羊淘券用户，否则您和您的监护人应承担因此而导致的一切后果，且小羊淘券有权注销（永久冻结）您的小羊淘券账号，并向您及您的监护人索偿或者追偿。</p>
      <p>小羊淘券并无能力对您的民事权利能力和民事行为能力进行实质性审查，因此一旦您进行了注册，小羊淘券可以视为您具备完全民事权利能力和完全民事行为能力。
      <p>2.在您使用小羊淘券产品或服务的过程中，我们会基于您需求的服务，收集和使用您的个人信息，相关功能会收集您的设备信息、身份信息、联系方式及交易信息等。《个人信息保护政策》介绍了您在使用我们的服务时，我们会如何处理您的个人信息和数据。请您务必认真阅读《个人信息保护政策》，在确认充分了解并同意后使用小羊淘券相关产品或服务。</p>
      <p>3.在您使用小羊淘券产品或服务时，需要在联网的状态下完成，因此产生的流量费由您的电信服务提供商收取，具体收费标准请您向其咨询。
    <div class="secondTitle">二、小羊淘券客户端</div>
      <p>1.小羊淘券客户端（小羊淘券APP、小程序的统称，下同）是一款免费的无自营、无自营商品的社交电商导购平台，旨在为消费者提供完善的购物导购、促销信息、领取优惠券、推广返利等综合导购服务，同时由小羊淘券向用户提供技术及服务。</p>
      <p>2.小羊淘券app小羊淘券仅作为互联网信息服务导购平台，为链接第三方淘宝（即由浙江淘宝网络有限公司经营的域名为taobao.com的网络零售网站平台）、天猫（即由浙江天猫网络有限公司经营的域名为tmall.com的网络零售网站平台）、京东（即北京京东世纪贸易有限公司经营的域名为JD.com 的网络零售网站平台）、唯品会（即广州唯品会电子商务有限公司经营的域名为vip.com 的网络零售网站平台）、拼多多（即上海寻梦信息技术有限公司经营的域名为pinduoduo.com 的网络零售网站平台）等电子商务平台（“外部电商平台”）及平台入驻商家，并通过与第三方交易平台或在第三方交易平台入驻的商家达成推广引流合作，以获取第三方交易平台内的商品优惠信息或相关商家就具体商品的实际返利信息，促成相关平台或平台商家的商品成交。小羊淘券并非与您进行商品交易的销售者，仅为平台及商家商品及优惠信息的展示平台，您最终的商品交易行为仍在上述（但不限于）电子商务平台完成。</p>
      <p>3.小羊淘券的商业模式为依托自研并运营的应用程序、社群、KOL及消费者就第三方交易平台或平台入驻商家的商品优惠信息进行宣传和推广，加速商品信息的分发传递效率，构筑多方共赢的去中心化的普惠社交电商平台，带动产业效率升级。商家提高投放收益和效率，平台获取流量带动业绩增长，KOL通过社群推广获取收益，消费者节省开支、提升生活品质。</p>
      <p>您可以通过使用小羊淘券获取商家优惠券，商品成交后获得商家返利。您自购或分享链接推荐购物的返利来自于商家支付的推广费。同时，为了鼓励您使用和推广小羊淘券app，小羊淘券设置了不同营销奖补活动，如任务值数字营销活动、超级补贴、推单活动等，用户可通过上述活动获得相应的活动推广奖励。</p>
    <div class="secondTitle">三、账号注册与使用</div>
      <p>1．在您签署本协议，完成注册程序或以其他小羊淘券提供的方式实际使用小羊淘券服务时，小羊淘券会向您提供唯一编号的用户账号，注册成功即为小羊淘券用户。小羊淘券账号的所有权归小羊淘券所有，用户完成申请注册手续后，获得小羊淘券账号的使用权，该使用权仅属于初始申请注册人，禁止赠与、借用、租用、转让或售卖，用户应谨慎合理地保存、使用其用户名和密码。</p>
      <p>2．您可以对账号设置用户名和密码，通过该用户名密码或与该用户名密码关联的其它用户名密码登陆小羊淘券客户端。您设置的用户名不得侵犯或涉嫌侵犯他人合法权益。</p>
      <p>3．您在注册及使用小羊淘券服务时，必须向小羊淘券提供真实、准确、完整、合法有效的资料，且有义务维持并及时更新相关资料。您应当保证在账号名称、头像等注册信息中不得出现违法和不良信息。如果您提供的资料违反本款要求，您需自行承担因此造成的任何直接或间接支出及责任，并且小羊淘券保留终止您使用小羊淘券各项服务或采取其他处理措施的权利。</p>
      <p>4．作为小羊淘券服务的提供者，为使您更好地使用小羊淘券的各项服务，保障用户的账号安全，小羊淘券有权要求您按照我国法律规定完成实名认证。您应提供真实资料进行认证，如您的姓名、电子邮件地址、联系电话、联系地址等。若涉及第三方支付、资金结算或其他可能需要您提供银行卡信息的业务，您应当保证提供的银行账户信息或第三方支付账户信息真实、有效、合法，且属于您本人所有，小羊淘券或我们合作的第三方支付机构将根据您提供的银行账户信息或第三方支付账户信息与您进行资金结算。您提供的银行张户信息或第三方支付账户信息错误或您拒绝提供，您应当理解您将无法使用小羊淘券客户端部分功能、第三方支付机构提供的支付功能以及您将无法获得结算资金。您理解并同意如因您未能提供有效的上述信息导致资金无法结算时，该部分资金不会因为未支付给您而产生利息，另外我们可以向您发送通知以便于您可以及时提供信息，若超出一定时间未得到回复，我们有权收回该部分结算资金自行处理。</p>
      <p>5．一个账号仅限一个用户使用，您必须向小羊淘券提供真实确实的信息，对于由于资料提供不正确导致返利/奖金等款项提取无法收到等后果，小羊淘券不承担任何责任。小羊淘券有权就您提供的信息的完整性、真实性、正确性等发出询问及/或要求改正的通知，若您未能在小羊淘券要求的合理期限内回复小羊淘券的询问及/或完成改正，小羊淘券有权做出删除相应资料并暂停账号的处理，直至终止对您提供部分或全部小羊淘券服务。小羊淘券对此不承担任何责任，您将承担因此产生的任何成本或支出。</p>
      <p>6．您应当对您注册或使用的用户账号进行的所有活动、操作及言论负完全的责任。您同意妥善保管好自己的账号及密码，不向其他任何人泄露该账户号码及密码，亦同意在任何情况下不使用其他用户的账号或密码，不得冒充他人，也不得使用注册账号导致其他用户误认，否则小羊淘券有权立即停止提供服务，收回其账号并由您独自承担由此而产生的一切法律责任。</p>
      <p>7．如您发现由他人冒用或盗用您的账号、密码、任何其他未经合法授权或因任何原因受到潜在或显示危险之情形时，应立即以有效方式通知小羊淘券，同意授权小羊淘券采取一切合理措施以保证用户的利益不受损害，并认可该等措施所产生的法律效果归于用户自身。同时，您理解小羊淘券对您的请求采取行动需要合理期限，您知悉在获得您前述有效通知及合理期限届满之前，小羊淘券可能或实际已执行相应指令及（或）您的损失可能或实际已经发生，对于该等损失，小羊淘券无需承担任何责任。</p>
      <p>8．小羊淘券公司向您提供相关产品及服务，基于此，小羊淘券有权根据订单返利收取10%的技术服务费。您在使用小羊淘券产品或服务过程中，所产生的应纳税费，以及一切硬件、软件、服务、账号维持及其他方面的费用，均由您独自承担。其中即日起已连续超过或将来连续超过365天 (含) 未登录小羊淘券APP的用户，由于您长时间未使用小羊淘券app，您清楚小羊淘券为了维护保存您的数据会产生数据管理成本。基于此，小羊淘券有权按照每月1元的标准向您收取数据管理费用;您同意小羊淘券有权从您的账户余额的限度内扣除数据管理费用，余额不足以支付的，将不再额外向您收取数据管理费用。对于被小羊淘券账号冻结或暂停账号的用户，小羊淘券将不再提供用户账号项目下的服务。</p>
      <p>9．因黑客行为或用户主动泄露、出借账户信息或保管疏忽等非小羊淘券的原因导致账号、密码遭他人非法使用或遭受第三方攻击、诈骗等导致的损失及后果，小羊淘券不承担任何责任。</p>
      <p>10．小羊淘券为您提供账号注销功能，您可以通过该注销功能注销您的个人账号（详情请见《小羊淘券账号注销规则》）。我们在此善意的提醒您，您注销账户的行为会给您的售后维权带来诸多不便，且注销账户后，您的个人信息我们只会在前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓，根据法律法规规定，相关交易记录可能须在后台保存5年甚至更长时间。</p>
    <div class="secondTitle">四、个人信息的收集、使用和保护</div>
      <p>1． 小羊淘券在您使用相关产品或服务时收集、使用、储存、分享和保护用户的个人信息规则请详见《个人信息保护政策》。本协议关于个人信息保护的内容与小羊淘券制定的关于个人信息保护的其他规则相冲突的，及本协议对个人信息保护相关内容未作明确规定的，均应以关于个人信息保护的其他规则的内容为准。
      <p>2．保护用户个人信息是小羊淘券的一项基本政策，小羊淘券承诺不对外公开或向第三方机构透露用户涉及隐私的信息。我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
      <p>（1）根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
      <p>（2）根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们会依据所要求的个人信息类型和披露方式公开披露您的个人信息（如当法院、政府主管部门要求或小羊淘券为维护自身、第三方合法利益时，用户同意小羊淘券有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等）。</p>
      <p>3．您还必须遵守小羊淘券（及合作组织）的使用/服务条款及个人信息保护政策。</p>
      <p>4．关于信息的存储和安全。</p>
      <p>（1）您的个人信息将全被存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、个人信息保护政策以及其他任何相关的保密和安全措施来处理这些个人信息。
      <p>（2）我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。尽管提供这些安全措施，但小羊淘券不保证这些信息的绝对安全。</p>
      <p>5．我们仅会在以下情况下，公开披露您的个人信息：</p>
      <p>（1）获得您明确同意后；</p>
      <p>（2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>
    <div class="secondTitle">五、用户使用规则及处理规定</div>
    <p>（一）在使用小羊淘券产品或服务过程中，您承诺遵守下列使用规则：</p>
    <p>1.用户不得出现扰乱小羊淘券客户端秩序的行为，如恶意注册恶意点击等行为（例如短期内大量频繁多次注册或点击）。</p>
    <p>2.您承诺不得以任何方式利用小羊淘券直接或间接从事违反中国法律以及社会公德的行为，小羊淘券有权对违反上述承诺的内容予以删除。用户在使用小羊淘券服务时所传送、发布的任何内容仅表明其个人的观点和立场，并不反映或代表，也不得被视为反映或代表小羊淘券的观点、立场或政策；用户作为内容的发布者，需自行对所发布内容负责并承担全部法律责任。小羊淘券不对用户传送、发布的内容的正确性、完整性进行保证，除法律法规规定的义务外，小羊淘券不承担任何其他责任。</p>
    <p>3.遵守诚实信用原则，不采取不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为。小羊淘券不承认用户的线下交易行为。凡非经小羊淘券客户端渠道进行的交易渠道、支付等行为，小羊淘券有权不予认可。</p>
    <p>4.不以虚构或歪曲事实的方式不当评价其他用户，不采取不正当方式制造或提高自身的信用度，不采取不正当方式制造或提高（降低）其他用户的信用度。</p>
    <p>5.不对小羊淘券客户端上的任何数据作商业性利用，包括但不限于在未经小羊淘券事先书面同意的情况下，以复制、传播等任何方式使用小羊淘券站上展示的资料。</p>
    <p>6.小羊淘券严禁用户通过以下行为获得利益，一经发现，小羊淘券有权追回用户账号及其关联账号（关联账户由小羊淘券根据自身系统数据判定，判定依据包括但不限于用户登记信息、交易信息、第三方反馈信息、设备终端信息、IP地址等，下同）中已经给予的优惠权益、推广奖励，并有权直接在小羊淘券客户端用户账户及其关联账户上抵减已经给予的优惠权益、推广奖励，并视情节严重可中止用户账号直至注销用户账号，同时该用户必须承担由此给小羊淘券带来的所有损失：</p>
    <p>（1)购买产品后以任何形式或以任何理由取消订单或退货退款的；</p>
    <p>（2)劫持流量；</p>
    <p>（3)自买自卖；</p>
    <p>（4)劫持其他用户的正常访问链接使其变成推广链接；</p>
    <p>（5)骗取其他用户点击其设置的非小羊淘券设置的链接；</p>
    <p>（6)违反购物所在网站用户协议及其规则；</p>
    <p>（7)利用技术漏洞或规则下单;</p>
    <p>（8)协助或教唆他人从事本条款所禁止的行为；</p>
    <p>（9)其他通过损害小羊淘券客户端或任何第三方合法权益或扰乱小羊淘券小羊淘券客户端秩序的行为；</p>
    <p>（10)其他违反小羊淘券用户协议或规则的行为；</p>
    <p>（11)其他违反法律法规或者违反诚实信用、公平原则的行为。</p>
    <p>7.小羊淘券严禁各种针对小羊淘券活动的作弊行为。对于查实的作弊行为，我们有权收回该账号及其关联账号全部的奖励、取消邀请资格，并列入小羊淘券黑名单账户。作弊行为包括但不限于：使用相同的电脑或设备，相同的IP地址在同一天内注册多个账号，以骗取邀请奖励的行为；以注册送钱及其他方式等利益诱导用户来注册小羊淘券获取奖励的行为；获取返利后取消交易或退款退货或拒不退还返利/奖励权益的行为等。</p>
    <p>8.小羊淘券禁止用户在小羊淘券的合作商城内进行任何形式的推广。</p>
   <p> 9.小羊淘券禁止用户从事以下扰乱小羊淘券客户端秩序的行为，一经发现，小羊淘券有权追究用户的相关责任，包括但不限于停止结算推广奖励、取消相关优惠权益、收回小羊淘券用户权限、列入小羊淘券黑名单账户、冻结账户或者注销账户等。如造成小羊淘券损失或者承担相应法律责任的，小羊淘券有权要求用户赔偿并最终承担相应的责任：</p>
   <p> a)恶意注册、恶意点击等行为（例如短期内大量频繁多次注册或点击）；</p>
    <p>b)通过任何不正当手段达成交易或参与活动，如使用插件、外挂等装置、软件或例行程序干预或试图干预小羊淘券客户端的正常运作或正在小羊淘券上进行的任何交易、活动；</p>
    <p>c)采取任何将导致不合理的庞大数据负载加诸小羊淘券网络设备的行动；</p>
    <p>d)发布或假冒小羊淘券或第三方发布任何诈骗或虚假或违规信息；</p>
   <p> e)利用小羊淘券或第三方传播恶意程序或计算机病毒；</p>
   <p> f)协助或教唆他人从事本条款所禁止的行为；</p>
    <p>g）其他扰乱小羊淘券交易秩序或管理秩序或小羊淘券客户端其他秩序的行为。</p>
    <p>10.您了解并同意小羊淘券有权作如下处理：</p>
    <p>（1）小羊淘券有权对您是否违反上述承诺做出单方认定，并根据单方认定结果适用规则予以处理，这无须征得您的同意。</p>
    <p>（2）经国家行政或司法机关的生效法律文书确认您存在违法或侵权行为，或者小羊淘券根据自身的判断，认为您的行为涉嫌违反本协议和/或规则的条款或涉嫌违反法律法规的规定的，则小羊淘券有权在客户端上公示您的该等涉嫌违法或违约行为及小羊淘券已对您采取的措施。</p>
    <p>（3）对于您在小羊淘券上发布的涉嫌违法或涉嫌侵犯他人合法权利或违反本协议和/或规则的信息，小羊淘券有权予以删除，且按照规则的规定进行处罚。</p>
    <p>（4）对于您在小羊淘券上实施的所有行为，包括您未在小羊淘券上实施但已经对小羊淘券及其用户或第三方产生影响或损害小羊淘券或任何第三方合法权益的行为，小羊淘券有权单方认定您行为的性质及是否构成对本协议和/或规则的违反，并据此作出相应处罚。您应自行保存与您行为有关的全部证据，并应对无法提供充要证据而承担的不利后果。</p>
    <p>（5）对于您涉嫌违反承诺的行为对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保小羊淘券免于因此产生损失或增加费用。如小羊淘券因此承担相应责任或者赔偿相关损失，则您承诺小羊淘券可以向您追偿，相关责任或损失由您最终承担，相关损失包括合理的律师费用、相关机构的查询费用等。</p>
    <p>（6）如您涉嫌违反有关法律或者本协议之规定，使小羊淘券遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿小羊淘券因此造成的损失及（或）发生的费用，包括合理的律师费用、相关机构的查询费用等。</p>
    <p>（7）小羊淘券上展示的资料（包括但不限于文字、图表、标识、图像、数字下载和数据编辑）均为小羊淘券或其内容提供者的财产或者权利；小羊淘券上所有内容的汇编是属于小羊淘券的知识产权；小羊淘券上所有软件都是小羊淘券或其软件供应商的财产或者权利，上述知识产权均受法律保护。如您侵犯上述权利，小羊淘券有权根据规则对您进行处理并追究您的法律责任。</p>
    <p>（8）小羊淘券并无能力对您的相关注册、登记资料进行实质性审查，因此一旦因您的注册、登记资料的问题导致的相关后果应全部由您自己承担，小羊淘券对此不承担责任。如果根据法律法规要求小羊淘券先行承担了相关责任，那么您承诺小羊淘券有权向您追偿，由您最终承担上述责任。</p>
    <p>（二）用户在使用小羊淘券产品或服务的过程中，不得上载、复制、发布、传播或者转载如下内容：</p>
    <p>1.反对宪法所确定的基本原则的；</p>
    <p>2.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>3.损害国家荣誉和利益的；</p>
    <p>4.歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</p>
   <p> 5.宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
   <p> 6.煽动民族仇恨、民族歧视，破坏民族团结的；</p>
  <p>  7.破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
   <p> 8.散布谣言，扰乱经济秩序和社会秩序的；</p>
  <p>  9.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
   <p> 10.侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；</p>
   <p> 11.法律、行政法规禁止的其他内容。</p>
    <div class="secondTitle">六、第三方服务</div>
    <p>小羊淘券服务可能链接至第三方提供的电子商务交易或其他服务（包括网站或其他服务形式）。包括：</p>
    <p>1．您可利用“分享”键将某些内容分享到第三方服务，或您可利用第三方服务登录小羊淘券服务。这些功能可能会收集您的信息（包括您的日志信息），并可能在您的设备装置COOKIES，从而正常运行上述功能；</p>
    <p>2．我们通过广告或我们服务的其他方式向您提供链接，使您可以链接至第三方的服务或网站；</p>
   <p> 3．其他接入第三方服务的情形。例如，为实现本协议中声明的目的，我们可能会接入第三方SDK服务，并将我们依照本协议及《个人信息保护政策》收集的您的某些个人信息共享给该等第三方，以便提供更好的客户服务和用户体验。</p>
   <p> 该等第三方社交媒体或其他服务由相关的第三方负责运营。您使用该等第三方的电子商务交易服务或其他服务（包括您向该等第三方提供的任何信息），须受第三方自己的服务条款及信息保护声明（而非本协议）约束，您需要仔细阅读其条款。如您发现这些第三方电子商务交易或其他服务存在风险时，建议您终止相关操作以保护您的合法权益。</p>
    <div class="secondTitle">七、知识产权</div>
   <p> 1. 用户在小羊淘券上发表的内容（包括但不限于原创文章和评论等），知识产权均归用户本人或其他合法知识产权人所有。</p>
    <p>2.小羊淘券对以下内容享有全部知识产权，包括但不限于:编码、商标、服务标志、商号、图形、美术品、照片、肖像、文字内容、音频片段、按钮图标已及计算机软件、标识、数码下载、数据汇编都是小羊淘券或其内容提供者的财产，受到中华人民共和国版权相关法律法规的保护。您对其仅享有本协议约定范围内的临时的、可撤销的非专有使用权。</p>
    <p>3.您仅在符合小羊淘券客户端使用目的的前提下被许可浏览和使用小羊淘券客户端，即以个人名义浏览信息和获取导购服务的目的。其他方式的使用都是被严格禁止的，包括但不限于以下方式:复制、修改、销售、传送、再版、删除、添加、展览、记入或演示小羊淘券客户端的内容或以其他方式部分地或整体地非法使用小羊淘券客户端的内容，但经小羊淘券允许将小羊淘券客户端当中的资讯转发至微信朋友圈等第三方媒体的除外。</p>
    <p>4.您在小羊淘券客户端发布、上传的内容（包括但不限于文字、图片、视频、音频等）应有合法来源，相关内容为您所有或您已获得必要的授权。</p>
    <p>除非有相反证明，您理解并同意，为使用户内容得到更好的分享及推广，提高其传播价值及影响力，您授予小羊淘券免费的、不可撤销的、非排他的、无地域限制的许可使用，包括：存储、使用、传播、复制、修订、改编、汇编、出版、展示、翻译、表演用户内容或制作派生作品，以已知或日后开发的形式、媒体或技术将内容纳入其它作品，再许可第三方按照前述方式使用的权利，以及以自身名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行取证、发起投诉或提起诉讼的权利。</p>
    <p>为免歧义，上述许可包括使用、复制和展示用户内容中受保护的个人形象、肖像、姓名、商标、品牌、标识及其他营销推广素材、物料的权利和许可。</p>
    <p>5.尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。</p>
    <div class="secondTitle">八、特别授权</div>
    <p>您完全理解并不可撤销地授予小羊淘券下列权利：
    <p>1．因返利是由您所购买产品的商家提供的销售优惠，您推广渠道的用户完成交易后您将可获得该消费的推广奖励提成。您同意授权上述返利、推广奖励由小羊淘券代为支付。但由于商家所在平台未能按时支付款项，则小羊淘券有权不予支付返利金额。如您或您推广渠道的用户购买产品后以任何形式任何理由取消订单或退货退款的或从事本协议禁止的行为导致交易未能最终顺利完成的，您确认授予小羊淘券就您账户的返利、推广奖励直接抵减的权利。</p>
    <p>2．一旦您向小羊淘券或其关联公司或其合作组织作出了任何形式的承诺，且相关公司或组织已确认您违反了该承诺，则小羊淘券有权立即按您的承诺或协议约定的方式对您的账户采取限制措施，包括中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，除非法律法规另有明确要求，小羊淘券无须就相关确认与您核对事实，或另行征得您的同意，且小羊淘券无须就此限制措施或公示行为向您承担任何的责任。</p>
    <p>3．一旦您违反本协议，或与小羊淘券签订的其他协议的约定，小羊淘券有权以任何方式通知小羊淘券关联公司或其合作组织，要求其对您的权益采取限制措施，包括但不限于要求小羊淘券将您账户内的收益支付给小羊淘券指定的对象，要求关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。</p>
    <p>4．小羊淘券及其关联公司有权受理您与其他用户因交易产生的争议，并有权单方判断与该争议相关的事实及应适用的规则，进而作出处理决定。该处理决定对您有约束力。如您未在限期内执行处理决定的，则小羊淘券及其关联公司有权利（但无义务）直接处分您小羊淘券账户内的收益。如该争议造成小羊淘券及其关联公司损失的，您应及时弥补小羊淘券及其关联公司的损失，否则小羊淘券及其关联公司有权直接抵减您在合同项下和账户中的权益，并有权继续追偿。</p>
    <p>5．如您有发现其他小羊淘券用户有实施本协议第五条中的行为，请把相关信息及证据材料向小羊淘券进行举报。举报/投诉渠道请登录小羊淘券客户端—“我的”—意见反馈，或发送电子邮件至13352593316@163.com。</p>
    <div class="secondTitle">九、责任与限制</div>
    <p>1．小羊淘券负责按"现状"和"可得到"的状态向您提供服务，且小羊淘券仅对自身提供的服务承担责任。小羊淘券对于第三方向用户提供的服务和产品不提供保证也不承担任何责任，但应当协助用户和第三方进行交涉谈判以维护用户合法权益。</p>
    <p>2．您了解小羊淘券上的信息系第三方或者其他用户提供，可能存在风险和瑕疵；您清楚并明白小羊淘券上的商品数据来源于其对应的第三方平台，仅供参考，具体以第三方平台实际商品交易页面为准，小羊淘券仅作为信息服务提供展示。小羊淘券仅作为您获取物品或服务信息、物色交易对象、就物品和/或服务的交易进行协商及开展交易的场所，但小羊淘券无法控制交易所涉及的物品的质量、安全或合法性，商贸信息的真实性或准确性，以及交易各方履行其在贸易协议中各项义务的能力。您应自行谨慎判断确定相关物品及/或信息的真实性、合法性和有效性，并自行承担相关风险。</p>
    <p>3．小羊淘券上的商品价格、数量、是否有货等商品信息随时有可能发生变动，小羊淘券不就此作出特别通知。您知悉并理解由于网站上商品信息数量极其庞大，虽然小羊淘券会尽合理的最大努力保证您所浏览的商品信息的准确性、迅捷性，但由于众所周知的互联网技术因素等客观原因，小羊淘券显示的信息可能存在一定的滞后性和差错，由此给您带来的不便或产生相应问题，小羊淘券不承担责任。请您注意商品的具体售价以订单结算页价格为准，如您发现活动商品售价或促销信息有异常，请您购买前先咨询客服。</p>
    <p>4．关于价格说明：划线价格：指商品的专柜价、吊牌价、正品零售价、厂商指导价或该商品的曾经展示过的销售价，并非原价，仅供参考。未划线价格，指商品的实时标价，不因表述的差异改变性质。具体成交价格根据商品参加活动，或用户使用优惠券、收益等发生变化，最终以订单结算页价格为准。商家详情页（含主图）以图片或文字形式标注的一口价、促销价、优惠价等价格可能是在使用优惠券、满减或特定优惠活动和时段等情形下的价格，具体请以结算页面的标价、优惠条件或活动规则为准。此说明仅当出现价格比较时有效，若商家单独对划线价格进行说明，以商家的表述为准。</p>
    <p>5．除非法律法规明确要求，或出现以下情况，否则，小羊淘券没有义务对所有用户的注册数据、商品（服务）信息、交易行为以及与交易有关的其它事项进行事先审查：</p>
    <p>（1）小羊淘券有合理的理由认为特定用户及具体交易事项可能存在重大违法或违约情形。</p>
    <p>（2）小羊淘券有合理的理由认为您在小羊淘券的行为涉嫌违法或不当。</p>
    <p>6．您理解并同意，小羊淘券及其关联公司并非司法机构，仅能以普通人的身份对证据进行鉴别，小羊淘券及其关联公司对争议的调处完全是基于您的委托，小羊淘券及其关联公司无法保证争议处理结果符合您的期望，也不对争议调处结论承担任何责任。如您因此遭受损失，您同意自行通过法律途径向受益人或者其他相关人员索偿。</p>
    <p>7．您了解并同意，小羊淘券不对因下述任一情况而导致您的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其它无形损失的损害赔偿(无论小羊淘券是否已被告知该等损害赔偿的可能性)：</p>
    <p>（1）第三方未经批准的使用您的账户或更改您的数据。</p>
    <p>（2）通过小羊淘券提供的服务购买或获取任何商品、样品、数据、信息或进行交易等行为或替代行为产生的费用及损失，但因小羊淘券提供的服务信息错误所导致的除外。</p>
    <p>（3）您对小羊淘券服务的误解。
    <p>（4）任何非因小羊淘券的原因而引起的与小羊淘券服务有关的其它损失。
    <p>8．因使用小羊淘券服务而引起的任何损害或经济损失，小羊淘券承担的全部责任均不超过您通过小羊淘券所购买的或与该索赔有关的服务或商品的价格。本责任限制条款在用户资格被冻结、暂停或注销后仍继续有效。</p>
    <p>9．不论在何种情况下，小羊淘券均不对由于罢工、暴乱、起义、骚乱、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令，以及其它非因小羊淘券的原因而造成的不能服务或延迟服务承担责任。小羊淘券会尽合理努力处理善后事宜。</p>
    <div class="secondTitle">十、协议的生效、变更与终止</div>
    <p>1. 本协议自您勾选并点击“我已阅读并同意”本协议之时即生效。如未点击确认本协议之前您事实上已在使用小羊淘券客户端功能的，则本协议在您的事实行为发生之时生效。</p>
    <p>2.根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，小羊淘券将在必要时对本协议的服务条款作出修改或变更。小羊淘券将会在客户端或官网公布修改之后的协议内容，该公布行为视为小羊淘券已经通知您修改内容，修改后的内容一经公布即有效替代原有服务条款。小羊淘券也可能会采用电子邮件或私信的传送方式，提示用户协议条款的修改、服务变更、或其它重要事项。</p>
    <p>3. 此外，您可登录在小羊淘券客户端端中查阅最新版本的相关协议条款。如果您继续使用小羊淘券提供的服务，即视为您已经接受修改后的服务条款；如果您不接受修改后的服务条款，应当停止使用小羊淘券提供的服务。</p>
    <p>4. 您同意，小羊淘券有权依据本协议决定中止、终止向您提供部分或全部小羊淘券服务，暂时冻结或永久冻结（注销）您的账户，且无须为此向您或任何第三方承担任何责任。但本协议或法律法规另有明确要求的除外。</p>
    <p>5. 因本协议（含规则）变更时，您明示并通知小羊淘券不愿接受新的服务协议时，双方协议终止。</p>
    <p>6.您同意，您与小羊淘券的合同关系终止后，小羊淘券及其关联公司或者其合作组织仍享有下列权利：</p>
    <p>(1)在法律法规允许的范围内继续保存并使用您的注册、登记信息、数据及您使用小羊淘券服务期间的所有交易数据。</p>
    <p>(2您在使用小羊淘券服务期间存在违法行为或违反本协议和/或规则的行为的，小羊淘券仍可依据本协议向您主张权利。</p>
    <div class="secondTitle">十一、法律适用与争议解决</div>
    <p>1．本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。</p>
    <p>2.若用户和小羊淘券之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交小羊淘券注册所在地有管辖权的人民法院管辖。</p>
    <p>3. 本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。</p>
    <p>4. 本协议所有条款的标题仅为方便阅读所设，本身并无实际涵义，非对条款的定义、限制、解释或描述其范围或界限，不能作为本协议涵义解释的依据。</p>
    <p>5. 新版用户协议生效前，用户的行为应遵循目前生效的协议；新版用户协议生效后，按更新后版本执行。</p>
    <div class="secondTitle">十二、其他</div>
    <p>1.小羊淘券客户端软件的所有人为大庆一只羊科技有限公司，是一家注册地在黑龙江省大庆市高新技术开发区的提供购物返利、促销信息、活动导购、广告等技术运营服务支持的互联网公司，您因自购或通过自有链接分享成交后所获得的第三方电商平台返利将由其与您进行结算。</p>
    <p>2.小羊淘券（广州）数字科技有限公司是为小羊淘券客户端提供技术运营支持与大数据营销服务的数字软件公司，您因参与小羊淘券客户端或第三方电商平台奖励、推广营销及数字营销奖励（如任务值专享限时补贴活动）等奖励权益将由该公司与您进行结算。您参与此类营销活动所获得全部权益均具有时效性，若您超过一定期限未能及时领取，小羊淘券平台有权收回该部分权益，具体回收规则以对应的营销活动规则约定为准。</p>
    小羊淘券对上述返利、平台奖励、推广营销及数字营销奖励及其支付方式享有最终的解释权。
    <p>3.小羊淘券未能及时行使本协议项下的任何权利不构成对任何权利的放弃。
   <p> 4．名词释义
    <p>除您与小羊淘券另有约定外，本协议及其补充协议和个人信息保护政策当中的下列名词均采用如下解释：</p>
    <p>（1）小羊淘券客户端：即小羊淘券享有全部著作权的、提供给您及其他用户登录和使用的APP、小程序等客户端的统称。</p>
    <p>（2）小羊淘券服务条款：即本协议，指您与小羊淘券当下订立的旨在约定您登录、使用小羊淘券客户端，以及通过小羊淘券客户端获取优惠券信息，转入第三方平台下达订单、购买商品、支付货款并收取商品等整个导购服务过程中，您与小羊淘券之间的权利、义务的书面合同。</p>
    <p>（3）CPS推广合作：指用户同意在其拥有和/或具有合法经营权、代理权的网站、推广渠道上投放本协议项下的小羊淘券推广链接，以方便用户对小羊淘券或小羊淘券客户端认可的其他电商平台的访问以及购买商品；运营方同意用户在其拥有和/或具有合法经营权、代理权的推广渠道上设置上述链接，并在本协议规定的条件满足时，就小羊淘券合作之电商平台商品的销售按照相应的比例向用户给予返利。</p>
    <p>（4）小羊淘券CPS系统：指由运营方为用户提供小羊淘券客户端各项功能所依托的系统及其各种软件，只有依据小羊淘券客户端注册开通程序（包括签订本协议或软件许可协议）并成功注册、授权/备案成功的用户，才能使用小羊淘券CPS系统相关软件及小羊淘券客户端相关功能。</p>
    <p>（5）订单：指由小羊淘券客户端推广返利、奖励结算程序生成的记录您通过小羊淘券客户端第三方服务平台所购买的商品的名称、品牌、价款、折扣、优惠权益等交易信息的表格。这份文件将被用作所有可能发生的与您购买有关的询问、请求和争议的参考。</p>
    <p>（6）用户账号：指您通过登录小羊淘券客户端或者小羊淘券提供其他的用户账号注册渠道，注册获得并可据以登录小羊淘券客户端的账号。</p>
    <p>（7）小羊淘券规则：小羊淘券在网站、APP、小程序等客户端内已经发布及后续发布的各类规则规范、实施细则、解读、产品/服务说明、公告等。</p>
    <p>（8）返利：即若您按照CPS推广合作方式促成成交后，并在本协议规定的条件满足时（未发生退货、退款、取消订单等情况），小羊淘券合作之电商平台将根据商品的销售价格按照相应的比例向您给予的推广费用。</p>
    <p>（9）奖励：指小羊淘券或跟小羊淘券合作的第三方主流电子商务平台不定期组织策划的营销活动奖励，或满足平台相关条件时获得的收益激励/推广奖励，达成后小羊淘券或第三方电子商务平台将按照其设定的活动规则向您发放。</p>
    <p>附件:《小羊淘券账号注销规则》</p>
    <p>您在申请注销小羊淘券账号前，应当认真阅读《小羊淘券账号注销规则》（以下称“《注销规则》”）。</p>
    <p>请您务必审慎阅读、充分理解协议中相关条款内容，其中包括：</p>
    <p>1、与您约定免除或限制责任的条款；</p>
    <p>2、其他以粗体下划线标识的重要条款。</p>
   <p> 如您对本《注销规则》有任何疑问，可通过小羊淘券客户端联系专属客服，您也可通过小羊淘券客户端的“意见反馈”提出申请需求。</p>
    <p>【特别提示】当您按照注销页面提示阅读并同意本《注销规则》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销规则》的全部内容。阅读本《注销规则》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账号注销程序。</p>
    <p>亲爱的小羊淘券个人消费者用户：</p>
    <p>我们在此善意地提醒您，您注销账号的行为会给您的售后维权带来诸多不便，且注销小羊淘券账号后，您的个人信息我们只会在小羊淘券的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓并理解，根据相关法律规定相关交易记录须在小羊淘券后台保存5年甚至更长的时间。</p>
   <p> 1. 如果您仍执意注销账号，您的账号需同时满足以下条件：</p>
    <p>（1）在最近一个月内，账号没有进行更改密码、更改绑定信息等敏感操作，账户没有被盗、被封等风险；</p>
    <p>（2）账户在小羊淘券系统中无资产和虚拟权益（包括但不限于账户收益、用户权益、优惠券等）；</p>
    <p>（3）账号无任何纠纷，包括投诉举报或被投诉举报；</p>
    <p>（4）账号为正常使用中的账号且无任何账户被限制的记录；</p>
    <p>2. 小羊淘券账号一旦被注销将不可恢复，请您在操作之前自行备份小羊淘券账号相关的所有信息和数据。请您保存好订单商品和服务的交易凭证、票据等资料，否则您有可能须支付额外的账户和订单查询费用，或无法享受售后服务。</p>
   <p> 3. 在小羊淘券账户注销期间，如果您的小羊淘券账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，小羊淘券有权自行终止本小羊淘券账号的注销而无需另行得到您的同意。</p>
   <p> 4. 注销小羊淘券账号，您将无法再使用本小羊淘券账号，也将无法找回您小羊淘券账号中及与账户相关的任何内容或信息，包括但不限于：</p>
    <p>（1）您将无法登录、使用本小羊淘券账户；</p>
    <p>（2）本小羊淘券账号的个人资料和历史信息（包括但不限于用户名、头像、购物记录、粉丝数量和昵称等）都将无法找回；</p>
    <p>（3）您通过小羊淘券账号使用、授权登录或绑定小羊淘券账号后使用的小羊淘券相关或第三方的其他服务的所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得或即将获得的返利、推广奖励、优惠券、订单、用户权益、及其他卡券、未完成订单权益等视为您自行放弃，将无法继续使用。您理解并同意，小羊淘券无法协助您重新恢复前述服务。</p>
   <p> 5.如您确认放弃账号中所有已享有或即将享有的任何权益，并要求小羊淘券为您强制注销的，以上所有权益将视为您主动放弃并不再享有，同时可能引起的任何法律责任，与小羊淘券无关。</p>
   <p> 6. 注销本小羊淘券账号并不代表本小羊淘券账户注销前的账号行为和相关责任得到豁免或减轻。</p> -->
</div>
</template>

<script>

export default {
  name: 'User',
  data(){
    return{
    }
  }
}
</script>
<style scoped>
  .home{
    padding: 12px;
    text-align: left;
    color: #000;
  }
  .home p{
    text-indent: 2em;
    margin: 5px 0;
    word-wrap: break-word;
    white-space: break-spaces;
    word-break: break-all;
    line-height: 24px;
    font-size: 14px;
  }
  .home span{
    font-weight: bold;
  }
  .alignleft{
    text-align: left;
  }
  .home ul {
    margin: 0px;
    list-style: none;

  }
  .home ul li{
    text-align: left;
    line-height: 24px;
    font-size: 14px;
    word-wrap: break-word;
      white-space: break-spaces;
      word-break: break-all;
  }
  .aligncenter{
    text-align: center;
  }
  .fontWeight{
    font-weight: bold;
  }
  .secondTitle{
    font-weight: bold;
    font-size: 14px;
    text-indent: 2em;
  }
  .titleOne{
    margin: 10px 0;
    text-indent: 2em;
  }
</style>
