<template>
  <div class="businessLicense">
    <div class="businessLicenseBox" id="watermark"></div>
  </div>
</template>

<script>
import Watermark from '../utils/watermark'
export default {
    mounted() {
        this.setWater()
    },
    methods: {
      // 设置水印
      setWater() {
        Watermark.set({
          rotate: -30, // 水印角度
          x_space: 40, // 水印间隔宽度
          y_space: 40, // 水印间隔高度
          fontSize: '16px', // 水印字体
          fontFamily: '微软雅黑', // 水印
          text: '小羊淘券', // 水印内容
          opacity: '0.5',
          id: 'watermark'
        });
      }
    }
}
</script>

<style>
    .businessLicense{
        width: 100vw;
        height: 100vh;
    }
    .businessLicenseBox{
        background-image: url(../assets/businessLicense.jpg);
        background-size: contain;
        width: 100%;
        height: 73.4vw;
        position: relative;
    }
</style>