<template>
  <div class="sdklist">
    <h3 class="aligncenter">小羊淘券第三方SDK目录</h3>
    <div class="SDKbox">
      <p>
        为保障小羊淘券APP的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）、应用程序接口（API）、应用程序插件等代码或通过其他合作方式，以实现相关目的，本清单将帮助您了解我们对您个人信息的收集、使用情况。我们会尽到审慎义务，对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。
      </p>
      <p>
        我们接入的第三方SDK主要服务于您以及其他的用户的需求，因此在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK。我们会及时在本说明中向您公开说明接入第三方SDK的最新情况。以下相关第三方SDK收集前述信息发生信息泄露的，由相关第三方服务商承担相应的法律责任。目前小羊淘券App接入的第三方SDK目录清单如下：
      </p>
      <p class="partTitle">（1）QQ互联 SDK</p>
      <ul>
        <li>应用目的：QQ分享功能</li>
        <li>使用场景：用户使用QQ分享功能时使用</li>
        <li>
          涉及个人信息：设备标识符（Android如Android ID）、MAC地址、IP 地址、位置信息（如GPS、WLAN接入点、蓝牙）、分享图片或内容，应用列表、设备传感器信息、设备信息、SSID、BSSID
        </li>
        <li>SDK服务提供方：深圳市腾讯计算机系统有限公司</li>
        <li>收集方式：SDK自行采集</li>
        <li>合作方官网链接：<a href="https://connect.qq.com/">https://connect.qq.com/</a></li>
        <li>隐私政策：<a
            href="https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e"
            >https://wiki.connect.qq.com/qq%e4%ba%92%e8%81%94sdk%e9%9a%90%e7%a7%81%e4%bf%9d%e6%8a%a4%e5%a3%b0%e6%98%8e</a
          >
        </li>
      </ul>
      <p class="partTitle">（2）微信 SDK</p>
      <ul>
        <li>应用目的：支持微信分享</li>
        <li>使用场景：在用户使用微信分享时使用</li>
        <li>涉及个人信息：分享图片或内容、设备信息</li>
        <li>SDK服务提供方：深圳市腾讯计算机系统有限公司</li>
        <li>收集方式：SDK自行采集</li>
        <li>官网地址：<a href="https://open.weixin.qq.com/">https://open.weixin.qq.com/</a></li>
        <li>隐私政策：<a
            href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8"
            >https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</a
          >
        </li>
      </ul>

      <p class="partTitle">（3）新浪微博 SDK</p>
        <ul>
        <li>应用目的：帮助用户分享内容到微博</li>
        <li>使用场景：在用户将内容分享至微博时使用</li>
        <li>涉及个人信息：设备标识符（Android如IMEI、MEID、Android ID、IMSI、硬件序列号/Serial、ICCID、OAID，iOS如IDFA、IDFV）、设备MAC地址、WLAN接入点（如SSID、BSSID）、分享图片或内容、设备信息、IP地址</li>
        <li>SDK服务提供方：北京微梦创科网络技术有限公司</li>
        <li>收集方式：SDK自行采集</li>
        <li>官网地址：<a href="https://open.weibo.com/wiki/SDK">https://open.weibo.com/wiki/SDK</a></li>
        <li>隐私政策：：<a
            href="https://open.weibo.com/wiki/Sdk/privacy?app_id=27"
            >https://open.weibo.com/wiki/Sdk/privacy?app_id=27</a
          >
        </li>
      </ul>
    
      <p class="partTitle">（4）腾讯Bugly SDK</p>
      <ul>
        <li>应用目的：用于手机APP使用过程中的故障问题</li>
        <li>使用场景：故障分析时</li>
        <li>涉及个人信息：日志信息（包括：第三方开发者自定义日志、Logcat日志以及APP崩溃堆栈信息）、设备ID（包括：Android ID以及IDFA）、位置信息、读写存储空间、设备连接WiFi和基站信息（运营商、WI-FI MAC/WI-FI IP/SIM 状态）、系统名称、系统版本</li>
        <li>SDK服务提供方：深圳市腾讯计算机系统有限公司</li>
        <li>收集方式：SDK自行采集</li>
        <li>官网地址：<a href="https://bugly.qq.com/v2/">https://bugly.qq.com/v2/</a></li>
        <li>隐私政策：<a
            href="https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56"
            >https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56
          </a>
        </li>
      </ul>
      <p class="partTitle">（5）中国移动认证 SDK</p>
      <ul>
        <li>应用目的：一键登录</li>
        <li>使用场景：在用户使用移动手机号一键登录时使用</li>
        <li>涉及个人信息：WLAN接入点（如SSID、BSSID）、IP 地址、网络类型、网络地址、设备MAC地址、软件列表或运行中的进程、经纬度、本机号码信息</li>
        <li>SDK服务提供方：中移互联网有限公司</li>
        <li>收集方式：SDK自行采集</li>
        <li>官网地址：<a href="https://wap.cmpassport.com/resources/html/contract.html">https://wap.cmpassport.com/resources/html/contract.html</a></li>
        <li>隐私政策：<a
            href="https://wap.cmpassport.com/resources/html/contract2.html"
            >https://wap.cmpassport.com/resources/html/contract2.html</a
          >
        </li>
      </ul>

      <p class="partTitle">（6）中国联通认证 SDK</p>
      <ul>
        <li>应用目的：一键登录</li>
        <li>使用场景：在用户使用联通手机号一键登录时使用</li>
        <li>涉及个人信息：IP地址，手机号</li>
        <li>SDK服务提供方：中国联合网络通信有限公司</li>
        <li>收集方式：SDK自行采集</li>
        <li>官网地址：<a href="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html">https://opencloud.wostore.cn/authz/resource/html/disclaimer.html</a></li>
        <li>隐私政策：<a
            href="https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true"
            >https://opencloud.wostore.cn/authz/resource/html/disclaimer.html?fromsdk=true</a
          >
        </li>
      </ul>

      <p class="partTitle">（7）中国电信认证 SDK</p>
      <ul>
      <li>应用目的：一键登录</li>
      <li>使用场景：在用户使用电信手机号一键登录时使用</li>
      <li>涉及个人信息：手机号、网络信息、设备信息、IP地址、设备标识符</li>
      <li>SDK服务提供方：天翼数字生活科技有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://e.189.cn/sdk/agreement/show.do?order=2&type=main&appKey=&hidetop=true&isShowPre=&returnUrl=https%3A%2F%2Fe.189.cn">https://e.189.cn/sdk/agreement/show.do?order=2&type=main&appKey=&hidetop=true&isShowPre=&returnUrl=https%3A%2F%2Fe.189.cn</a></li>
      <li>隐私政策：<a
          href="https://e.189.cn/sdk/agreement/show.do?order=2&type=main&appKey=&hidetop=true&isShowPre=&returnUrl=https%3A%2F%2Fe.189.cn"
          >https://e.189.cn/sdk/agreement/show.do?order=2&type=main&appKey=&hidetop=true&isShowPre=&returnUrl=https%3A%2F%2Fe.189.cn</a
        >
      </li>
    </ul>
      <p class="partTitle">（8）穿山甲 SDK</p>
      <ul>
      <li>应用目的：广告投放及广告监测归因、反作弊</li>
      <li>收集及共享个人信息的类型/范围：设备信息【设备品牌、型号、软件系统版本、屏幕密度、屏幕分辨率、设备语言、设备时区、sim卡信息、CPU信息、可用存储空间大小；AndroidID、OAID；手机系统重启时间、磁盘总空间、系统总内存空间、IDFV；可选信息：无线网SSID名称、WiFi路由器MAC地址（BSSID）、设备的MAC地址；设备标识符（IMEI、IMSI、ICCID、GAID（仅GMS服务）、MEID、设备序列号build_serial、IDFA】、网络信息（IP地址、运营商信息、Wi-Fi状态、网络信号强度）、应用信息（应用包名、运行中的进程信息、版本号、应用前后台状态、软件列表信息）、传感器信息（加速度传感器、陀螺仪传感器、线性加速度传感器、磁场传感器、旋转矢量传感器）、广告信息（对广告的展示、点击及转化等交互数据）、位置信息（精确位置信息、粗略位置信息）、性能数据（崩溃数据、性能数据）</li>
      <li>SDK服务提供方：北京巨量引擎网络技术有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://www.csjplatform.com">https://www.csjplatform.com</a></li>
      <li>隐私政策：<a
          href="https://www.csjplatform.com/privacy?invite_code=100702"
          >https://www.csjplatform.com/privacy?invite_code=100702</a
        >
      </li>
    </ul>
      <p class="partTitle">（9）Mob SDK（SMS SDK）</p>
      <ul>
      <li>应用目的：提供短信验证码的请求和验证功能</li>
      <li>应用场景：提供短信验证码的请求和验证时使用</li>
      <li>涉及个人信息：系统运行信息（设备平台、系统版本、设备型号、设备厂商、设备品牌、屏幕分辨率）、设备标识信息【iOS广告标识符（IDFA）、国际移动设备识别码（IMEI）（仅2022年3月份之前的版本收集）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、Advertising ID、VAID、AAID、SIM卡识别码（ICCID）（仅在秒验产品中收集）、SIM卡序列号（仅2022年3月份之前的版本收集）、Android ID（仅2022年3月份之前的版本收集）、Serial Number（仅2022年3月份之前的版本收集）】、手机号码；可选信息：地理位置信息（粗略位置信息及/或精细位置信息）、应用列表信息、网络状态信息（IP地址、运营商信息、WiFi信息、基站信息、SSID、BSSID）</li>
      <li>SDK服务提供方：上海游昆信息技术有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://www.mob.com/">https://www.mob.com/</a></li>
      <li>隐私政策：<a href="https://www.mob.com/wiki/detailed?wiki=596&id=23"
          >https://www.mob.com/wiki/detailed?wiki=596&id=23</a
        >
      </li>
    </ul>
    <p class="partTitle">（10）TalkingData SDK</p>
      <ul>
      <li>应用目的：数据统计分析</li>
      <li>应用场景：进入后台后</li>
      <li>收集个人信息类型：设备信息(IMEI/MEID/MAC/AndroidID/IDFA/OpenUDID/GUID/SIM卡IMSI/广告标识符)、软件版本、操作日志、位置信息、应用列表信息、读写存储空间、设备连接WiFi和基站信息（运营商、WI-FI MAC/WI-FI IP/SIM 状态/BSSID/SSID/ICCID）、传感器数据</li>
      <li>SDK服务提供方：北京腾云天下科技有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://www.talkingdata.com/">https://www.talkingdata.com/</a></li>
      <li>隐私政策：<a
          href="https://www.talkingdata.com/privacy.jsp?languagetype=zh_cn"
          >https://www.talkingdata.com/privacy.jsp?languagetype=zh_cn</a
        >
      </li>
    </ul>
    <p class="partTitle">（11）MobTech ShareSDK</p>
      <ul>
      <li>应用目的：提供社会化分享服务</li>
      <li>应用场景：实现社会化分享时使用</li>
      <li>收集个人信息类型：系统运行信息、网络状态信息、iOS广告标识符（IDFA）、国际移动设备识别码（IMEI）、匿名设备标识符(OAID)、Android ID、国际移动用户识别码（IMSI）、应用列表信息、基站信息、社交平台OpenID、地理位置、个人位置信息（粗略定位及访问精准定位）、WIFI BSSID、WIFI SSID</li>
      <li>SDK服务提供方：上海游昆信息技术有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://www.mob.com/">https://www.mob.com/</a></li>
      <li>隐私政策：<a
          href="http://www.mob.com/about/policy"
          >http://www.mob.com/about/policy</a
        >
      </li>
    </ul>

      <p class="partTitle">（12）秒验 SDK（MobTech  SecVerifySDK）</p>
      <ul>
      <li>应用目的：实现手机号码一键验证功能</li>
      <li>应用场景：提供基于三大运营商网关取号能力的手机号码快速验证功能</li>
      <li>收集个人信息类型：系统运行信息（设备平台、系统版本、设备型号、设备厂商、设备品牌、屏幕分辨率）、网络状态信息（IP地址、运营商信息、WiFi信息、基站信息、SSID、BSSID）、匿名设备标识符(OAID)、国际移动用户识别码（IMSI）、Advertising ID、VAID、AAID、SIM卡识别码（ICCID）（仅在秒验产品中收集）。如需要采集与前述设备标识信息存在不一致情况的，以具体产品/服务项下所列明的具体采集情况为准。）、地理位置信息（粗略位置信息及/或精确位置信息）、应用列表信息、手机号码</li>
      <li>SDK服务提供方：上海游昆信息技术有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://www.mob.com/">https://www.mob.com/</a></li>
      <li>隐私政策：<a
          href="https://www.mob.com/wiki/detailed?wiki=639&id=78"
          >https://www.mob.com/wiki/detailed?wiki=639&id=78</a
        >
      </li>
    </ul>
      <p class="partTitle">（13）高德地图 SDK</p>
      <ul>
      <li>应用目的：帮助用户使用功能时定位位置</li>
      <li>应用场景：用户使用定位相关功能（外卖领券，外卖到店、同城酒店领券）时使用</li>
      <li>收集个人信息类型：设备标识符（Android如IMEI、MEID、OAID、硬件序列号/Serial、ICCID、Android ID、IMSI，iOS如IDFV、IDFA）、IP地址、设备MAC地址、位置信息（如GPS、蓝牙和基站）、WLAN接入点（如SSID、BSSID）、Wifi列表、设备传感器信息、应用信息、设备信息、网络信息</li>
      <li>SDK服务提供方：高德软件有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://lbs.amap.com/">https://lbs.amap.com/</a></li>
      <li>隐私政策：<a
          href="https://lbs.amap.com/pages/privacy/"
          >https://lbs.amap.com/pages/privacy/</a
        >
      </li>
    </ul>
    <p class="partTitle" v-if="version_code<10100">（14）京东开普勒 SDK</p>
    <ul v-if="version_code<10100">
      <li>应用目的：京东授权、监测</li>
      <li>应用场景：app账号跳转京东、app异常登录时使用</li>
      <li>收集个人信息类型：个人常用设备信息(包括硬件型号、设备MAC地址、操作系统类型、软件列表、唯一设备识别码(如IMEI/androidID/OAID/IDFA/OPENUDID/GUID、SIM卡IMSI信息在内的描述个人常用设备基本情况的信息));个人位置信息:已安装App信息</li>
      <li>SDK服务提供方：北京京东叁佰陆拾度电子商务有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://k.jd.com/">https://k.jd.com/</a></li>
      <li>隐私政策：<a
          href="https://union.jd.com/searchResultDetail?articleId=177726"
          >https://union.jd.com/searchResultDetail?articleId=177726</a
        >
      </li>
    </ul>
    <p class="partTitle" v-if="version_code>=10100">（14）阿里百川电商 SDK</p>
    <ul v-if="version_code>=10100">
      <li>应用目的：支持跳转到手淘打开商品详情页与淘宝APP授权等功能</li>
      <li>应用场景：在用户点击手淘商品详情页和淘宝APP授权时使用</li>
      <li>收集个人信息类型：设备标识符（Android如Android ID）、IP地址</li>
      <li>SDK服务提供方：浙江淘宝网络有限公司、淘宝（中国）软件有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://baichuan.taobao.com/docs/doc.htm?spm=a3c0d.7386797.0.0.3bfabe48Ig2l67&source=search&treeId=37&articleId=105002&docType=1">https://baichuan.taobao.com/docs/doc.htm?spm=a3c0d.7386797.0.0.3bfabe48Ig2l67&source=search&treeId=37&articleId=105002&docType=1</a></li>
      <li>隐私政策：<a
          href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html"
          >https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html</a>
      </li>
    </ul>
    <p class="partTitle" v-if="version_code>=10100">（15）京东联盟广告 SDK</p>
    <ul v-if="version_code>=10100">
      <li>应用目的：京东投放广告、打开商品、跳转京东APP等</li>
      <li>应用场景：在用户点击京东商品详情页时和跳转京东APP时使用</li>
      <li>收集个人信息类型：设备标识符（Android如Android ID、OAID）</li>
      <li>SDK服务提供方：北京京东叁佰陆拾度电子商务有限公司、 北京京东世纪贸易有限公司</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://kepler.jd.com/console/docCenterCatalog/docContent?channelId=26">https://kepler.jd.com/console/docCenterCatalog/docContent?channelId=26</a></li>
      <li>隐私政策：<a
          href="https://union.jd.com/searchResultDetail?articleId=177725"
          >https://union.jd.com/searchResultDetail?articleId=177725</a
        >
      </li>
    </ul>
    <p class="partTitle" v-if="version_code>=10100">（16）增长营销套件 SDK</p>
    <ul v-if="version_code>=10100">
      <li>应用目的：提供数据分析功能</li>
      <li>应用场景：分析用户行为数据时使用</li>
      <li>收集个人信息类型：设备标识信息（Android如Android ID、MEID、IMEI、ICCID、设备硬件序列号，iOS如IDFV）、MAC地址、IP地址、位置信息、产品崩溃以及错误信息。开发者应用名、应用包名、版本号，应用列表等已安装app信息</li>
      <li>SDK服务提供方：北京火山引擎科技有限公司、北京有竹居网络技术有限公司提供</li>
      <li>收集方式：SDK自行采集</li>
      <li>官网地址：<a href="https://www.volcengine.com/">https://www.volcengine.com/</a></li>
      <li>隐私政策：<a
          href="https://www.volcengine.com/docs/6285/72216"
          >https://www.volcengine.com/docs/6285/72216</a
        >
      </li>
    </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      version_code:10000
    };
  },
  created(){
    if(this.$route.query.version_code){
        this.version_code = this.$route.query.version_code
    }
  },
  methods: {},
};
</script>

<style scoped>
.sdklist {
  background-color: #fff;
  color: #000;
  padding: 8px;
}
.sdklist p {
  text-align: left;
  word-wrap: break-word;
  white-space: break-spaces;
  word-break: break-all;
  line-height: 24px;
  /* height: 20px; */
  margin: 5px 0px;
  text-indent: 2em;
  font-size: 14px;

}
.partTitle{
  font-weight: bold;
  font-size: 12px;
  text-indent: 0em !important;

}
.sdklist ul {
  margin: 0px;
}
.sdklist ul li{
  text-align: left;
  line-height: 24px;
  font-size: 14px;
  word-wrap: break-word;
    white-space: break-spaces;
    word-break: break-all;
}
.sdklist a {
  text-decoration: none;
}
.aligncenter {
  text-align: center;
}
</style>