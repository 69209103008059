<template>
  <div class="course">
    <div class="title">步骤一</div>
    <div class="introduceWord">打开淘宝，找到你想要购买的商品，长按文案复制链接或者文字。</div>
    <div class="intoduceSpan">* 或者也可以直接在「小羊淘券APP」搜索框输入想要购买的商品信息。</div>
    <div class="intoduceImg intoduceImg1"></div>
    <div class="title">步骤二</div>
    <div class="introduceWord">打开小羊淘券APP，自动弹出匹配搜索弹窗，点击对应电商平台的按钮，直接跳转到搜索结果页面。</div>
    <div class="intoduceImg intoduceImg2"></div>
    <div class="title">步骤三</div>
    <div class="introduceWord">进入商品详情页面，先看视频再购物可解锁更多返利佣金，平台会根据视频任务的完成度，匹配相对应金额。</div>
    <div class="intoduceImg intoduceImg3"></div>
    <div class="title">步骤四</div>
    <div class="introduceWord">首次点击【自购返利】按钮，弹出授权弹窗，点击【去授权】跳转授权界面，输入要授权的淘宝账号信息完成授权即可。</div>
    <div class="intoduceSpan">* 如果未完成授权，也可无返利直接购买，但由于没有授权，平台无法识别你的信息，所以不能得到佣金返利。</div>
    <div class="intoduceImg intoduceImg4"></div>
    <div class="title">步骤五</div>
    <div class="introduceWord">授权成功后，回到商品详情页，点击【自购返】，跳转淘宝，此时下单成功且确认收货之后，即可获得对应的返利。</div>
    <div class="intoduceSpan">* 赚钱小tips，点击分享赚，将信息分享给你的朋友，朋友通过你分享的链接购买，也可以得到对应的返利哦~</div>
    <div class="intoduceImg intoduceImg5"></div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.course{
    padding: 4.2666vw;
    text-align: left;
    font-family: PingFangSC, PingFang SC;
}
.title{
    font-size: 4.266vw;
    font-weight: 600;
    color: #323232;
    margin-bottom: 2.4vw;
    
}
.introduceWord{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 3.7333vw;
  color: #323232;
  line-height:5.6vw;
  text-align: left;
  font-style: normal;
  margin-bottom: 1.066vw;
}
.intoduceSpan{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 3.2vw;
  color: #323232;
  line-height: 4.53333vw;
  text-align: left;
  font-style: normal;
  margin-bottom: 2.133vw;
}
.intoduceImg{
    width: 91.46vw;
    height: 79.46vw;
    background-image: url(../assets/1@2x.png);
    background-size: contain;
    margin-bottom: 6.4vw;
    background-repeat: no-repeat;
}
.intoduceImg2{
  background-image: url(../assets/2@2x.png);
  height: 73.866vw;
}
.intoduceImg3{
  background-image: url(../assets/3@2x.png);
  height: 78.4vw;
}
.intoduceImg4{
  background-image: url(../assets/4@2x.png);
  height: 60.266vw;
}
.intoduceImg5{
  background-image: url(../assets/5@2x.png);
  height: 83.7333vw;
  margin-bottom: 22.66vw;
}
</style>