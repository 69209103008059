<template>
  <div class="FAQ">
    <div class="block" v-for="item in dataList" :key="item.index">
        <div :class="item.show?'blockTitle':'blockTitleHideline'" @click="showOrHide(item)">{{ item.title }}<span :class="item.show?'titleTagTop':'titleTagDown'"></span></div>
        <div v-if="item.show" >
            <div class="blockBody" @click="jumpToDetail(item2)" v-for="item2 in item.problemList"  :key="item2.index">{{ item2.index + "、" +item2.title}} <span  class="torightTag"></span></div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
        data(){
            return{
                dataList:[{
                    title:"一、关于订单问题",
                    index:1,
                    show:false,
                    problemList:[{
                        index:1,
                        title:"淘宝订单同步刷新时间问题",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"由于早期没有淘宝订单接口API权限，所以用户在淘宝上购买的商品订单信息为次日更新，比如：1号下的单，需要2号才可查看相关的订单返利信息。"
                    },{
                        index:2,
                        title:"订单展示信息",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"目前订单只会展示商品信息，以及下单时间，订单结算状态和预计返利等信息，其他信息不作为重点展示。"
                    }]
                },{
                    title:"二、返利结算",
                    index:2,
                    show:false,
                    problemList:[{
                        index:1,
                        title:"什么时候结算返利？",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"官方的结算时间为每月的25号，每月25号后可结算上个自然月已经确认收货的商品佣金返利，比如，当前是11.26日，则可结算10.1日到10.31日已经确认收货的商品锁带来的佣金返利。"
                    },{
                        index:2,
                        title:"结算返利包含哪些订单？",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"主要包括自购确认收货结算订单，以及分享好友后好友下单并确认收货的返利订单。"
                    },{
                        index:3,
                        title:"哪些订单不会被计入返利收入？",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"●比价订单：用户先在电商平台浏览了A商品，短时间内在小羊淘券下单了A商品，结算佣金自动变为0元。<br/>●审核失败订单：全部退款订单，维权订单等。<br/>●饿了么，美团等订单暂时不会计入返利的计算中，后续进行技术升级陆续也会计入返利佣金结算中来。"
                    },{
                        index:4,
                        title:"提现失败",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"首先需要填写正确的支付宝账号和真实姓名，支付宝账号可以在支付宝个人信息界面查看，真实姓名则为你支付宝账号实名认证的姓名，请确保以上信息填写正确才可以正确提现，一般提现都是实时到账。"
                    }]
                },{
                    title:"三、福利任务",
                    index:1,
                    show:false,
                    problemList:[{
                        index:1,
                        title:"分享好友",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"通过分享邀请好友下载小羊淘券APP，并且好友在“我的”频道“邀请码”模块填写对应的邀请码信息，你可以得到2次的任意商品的超高返利兑换福利，当然如果你邀请了多个好友，次数也是可以累加的。"
                    },{
                        index:2,
                        title:"看视频提高返利",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"用户在购买商品前，完成看视频任务，用户购买商品对应的返利金额也会得到对应的提升，并且最多看2次则会提升到该商品的最大返利值。"
                    }]
                },{
                    title:"四、授权",
                    index:1,
                    show:false,
                    problemList:[{
                        index:1,
                        title:"淘宝，拼多多授权问题",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"目前需要授权的平台主要有淘宝和拼多多，如果想要购买这两个平台的商品，并且拿到对应的返利，需要授权之后再进行购物，当然也可以不授权直接购买商品，但是这种方式没有返利佣金，并且订单列表中也不会查到对应的下单购买记录。"
                    },{
                        index:2,
                        title:"不授权是否可以购物？",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"可以购物，但是如果是购买淘宝、和拼多多的商品不授权则得不到对应的佣金，并且在对应的订单列表中也查不到对应的信息。"
                    }]
                },{
                    title:"五、APP其他常见问题",
                    index:1,
                    show:false,
                    problemList:[{
                        index:1,
                        title:"邀请码入口",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"邀请码填写的入口在“我的”频道，常用功能中的”填邀请码“ 模块，并且每个用户只能有一个邀请人，但是每个设备可以邀请多个用户。"
                    },{
                        index:2,
                        title:"账号注销",
                        route:"/xiaoyang_shop/v1/faqdetail",
                        content:"一旦账号注销，将不会保留任何原来的信息，同时对应的没有体现的返利佣金也不在可提现，并且该账号对应的手机号也不可在进行小羊淘券APP的注册登录。"
                    }]
                }]
            }
        },
        methods:{
            showOrHide(i){
                i.show = !i.show
            },
            jumpToDetail(item){
                console.log(item)
                this.$router.push({
                    path:item.route,
                    query:{
                        title:item.title,
                        answer:item.content
                    }   
            })
            }
        }
    
}
</script>

<style scoped>
    .FAQ{
        text-align:left;
        background-color: #F7F7F7;
        min-height: 100vh;
        padding-top: 12px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-bottom: 12px;
    }
    .block{
        background-color: #fff;
        border-radius: 8px;
        margin: 12px;
        margin-top: 16px;
    }
    .titleTagTop{
        position: absolute;
        right: 0px;
        top:12px;
        width: 14px;
        height: 14px;
        background-image: url(../../assets/icon_more_top.png);
        background-size: contain;
    }
    .titleTagDown{
        position: absolute;
        right: 0px;
        top:12px;
        width: 14px;
        height: 14px;
        background-image: url(../../assets/icon_more_down.png);
        background-size: contain;
    }
    .block .blockTitle{
        font-weight: bold;
        padding: 12px;
        margin: 12px;
        font-size: 15px;
        border-bottom: 0.5px solid rgba(216, 216, 216, 0.5) ;
        position: relative;
    }
    .block .blockTitleHideline{
        font-weight: bold;
        padding: 12px;
        margin: 12px;
        font-size: 15px;
        position: relative;
    }
    .block .blockBody{
        margin: 12px;
        padding-bottom: 12px;
        font-size: 13px;
        position: relative;
    }
    .block .blockBody:nth-last-child(1){
        border-bottom: none;
    }
    .block .torightTag{
        position: absolute;
        right: 0px;
        top:0px;
        width: 12px;
        height: 12px;
        background-image: url(../../assets/icon_more_link.png);
        background-size: contain;
    }
    @media (-webkit-min-device-pixel-ratio:2) {
        .titleTagTop{
            position: absolute;
            right: 0px;
            top:12px;
            width: 14px;
            height: 14px;
            background-image: url(../../assets/icon_more_top@2x.png);
            background-size: contain;
        }
        .titleTagDown{
            position: absolute;
            right: 0px;
            top:12px;
            width: 14px;
            height: 14px;
            background-image: url(../../assets/icon_more_down@2x.png);
            background-size: contain;
        }
        .block .torightTag{
            position: absolute;
            right: 0px;
            top:0px;
            width: 12px;
            height: 12px;
            background-image: url(../../assets/icon_more_link@2x.png);
            background-size: contain;
        }
    }
</style>
