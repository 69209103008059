<template>
  <div class="businessCooperatiopn">
    <div class="QRcodeBox">
        <div class="QRcodeTitle">小羊淘券商务</div>
        <img class="QRcode" src="../assets/erweima.jpg"/>
        <div class="QRcodeWord">👆扫码添加微信洽谈合作</div>
    </div>
    <div class="email">邮箱联系<span class="mark">：</span><span class="emailAddress">yzy@xiaoyangtaoquan.com</span></div>
    <div class="memo">* 注<span class="mark">：</span>如回复不及时，建议优先联系企业微信</div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .businessCooperatiopn{
        width: 100vw;
        min-height: 100vh;
        background: #F7F7F7;
        padding-top: 12.8vw;
        box-sizing: border-box;
    }
    .QRcodeBox{
        width: 74.666vw;
        margin: 0 12.8vw 0vw 12.8vw;
        height: 103.2vw;
        background-image:url(../assets/bg@2x.png);
        background-size:contain;
        background-position:center;
        background-repeat: no-repeat;
        position: relative;
    }
    .QRcode{
        width: 49.87vw;
        height: 49.87vw;
        position: absolute;
        left: 12.2667vw;
        top: 34.667vw;
        /* background-image:url(../assets/erweima.jpg);
        background-size:contain;
        background-position:center;
        background-repeat: no-repeat; */
    }
    .QRcodeTitle{
        position: absolute;
        top: 20.53vw;
        width: 100%;
        text-align: center;
        font-family: AppleColorEmoji;
        font-size:4vw;
        font-weight: 500;
        color: #323232;
        line-height: 5.6vw;
        font-style: normal;
    }
    .QRcodeWord{
        position: absolute;
        top: 89.8667vw;
        width: 100%;
        text-align: center;
        font-family: AppleColorEmoji;
        font-size: 3.4667vw;
        color: rgba(144, 144, 144, 1);
        line-height: 5.6vw;
        font-style: normal;
    }
    .email{
        height: 5.866vw;
        margin-top: 11.73vw;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 4vw;
        text-align: center;
        color: #323232;
        line-height: 5.6vw;
        font-style: normal; 
        margin-bottom: 1.0667vw;
    }
    .email .mark{
        vertical-align: middle;
        padding-bottom: 1.3vw;
        margin-left: 0.333vw;
        display: inline-block;
    }
    .email .emailAddress{
        vertical-align: middle;
        padding-bottom: 2.1vw;
        margin-left: -1.333vw;
        display: inline-block;
    }
    .memo{
        text-align: center;
        height: 4.8vw;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 3.4666vw;
        color: #646464;
        line-height: 4.8vw;
        font-style: normal;
    }
    .memo .mark{
        vertical-align: middle;
        padding-bottom: 1.3vw;
        margin-left: 0.333vw;
        display: inline-block;
        margin-right: -0.8vw;
    }
</style>