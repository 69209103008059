<template>
  <div class="serviceAgreement">
    <div class="block" v-for="(item,index) in dataList" :key="index" @click="goDetail(item.url)">
        {{ item.title }}<span  class='torightTag'></span>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            dataList:[{
                title:"《中国移动认证服务条款》",
                url:"https://wap.cmpassport.com/resources/html/contract.html"
            },{
                title:"《中国联通认证服务条款》",
                url:"https://ms.zzx9.cn/html/oauth/protocol2.html"
            },{
                title:"《中国电信认证服务条款》",
                url: "https://e.189.cn/sdk/agreement/detail.do"
            }]
        }
    },
    methods:{
        goDetail(url){
            window.open(url)
        }
    }
}
</script>

<style scoped>
    .serviceAgreement{
        padding: 0 12px 0;
        text-align: left;
    }
    .block{
        height: 52px;
        line-height: 52px;
        border-bottom: 1px solid #e1e1e1;
        position: relative;
        color: #323232;
        font-family: PingFangSC, PingFang SC;
        font-size:14px;
    }
    .block .torightTag{
        position: absolute;
        right: 0px;
        top:20px;
        width: 12px;
        height: 12px;
        background-image: url(../assets/icon_more_link@2x.png);
        background-size: contain;
    }
</style>